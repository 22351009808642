// todo refactor

fieldset {
  &.fieldset--plain {
    margin: 0;

    &::before {
      content: none;
    }
  }

  &.fieldset--extra-top-space {
    margin-top: $space-unit * 3;
  }
}

.phone-form {
  display: flex;
  align-items: flex-start;

  .form-group {
    margin-top: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .invalid-feedback {
      align-self: flex-start;
    }

    input {
      min-height: 5.3rem;
      height: 5.3rem;
      max-height: 5.3rem;
    }
  }
}

input {
  &[type="date"]{
    min-height: 5.3rem;
    max-height: 5.3rem;
    height: 5.3rem;    
  }

  &[type="search"] {
    &::-webkit-search-decoration {
      display: none;
    }
  }
}

.mini-form {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &.registration-complete {
    max-width: 41.6rem;
  }
}

legend {
  @extend %primary-xs-u;
  padding: $space-unit * 4 0;
  display: block;
}

.note {
  @extend %primary-xxs;
  color: $black_secondary;
}

/* form group */

.form-group-title {
  display: block;
  @extend %primary-xxs;
  padding: 0 0 $space-unit;
  margin-bottom: $space-unit * 3;
}

.promo-code-form .form-group {
  border: 0;
}

.form-group {
  position: relative;
  background: transparent;

  input:not([type='radio']) {
    font-weight: 400;
  }

  input::placeholder {
    color: transparent;
  }

  input:not([type='radio']):placeholder-shown,
  input:not([type='radio']):not(:placeholder-shown):is(:focus) {
    padding: 2.4rem 1.6rem 0.6rem;
  }

  input:not([type="radio"]):placeholder-shown+label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 0) scale(1);
  }

  input::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not([type="radio"]):focus+label {
    transform: translate(0, -1.6rem) scale(0.9);
    cursor: pointer;
  }

  input:not([type="radio"]):focus {
    border: 0.1rem solid $blue;
  }

  input:not(:placeholder-shown):not(:focus):not([type="radio"])+label {
    transform: translate(0, -1.6rem);
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: $gray7;
    opacity: 0;
  }

  input:not([type="radio"])+label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1.4rem 1.6rem;
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: $gray7;
    background: transparent;
    transition: all 0.3s ease-out;
    transform-origin: left bottom;
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    cursor: text;
    pointer-events: none;
  }

  input:not([type="radio"]),
  select {
    -webkit-appearance: none;
    width: 100%;
    padding: 1.5rem 1.6rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: $blue;
    font-family: Satoshi-Regular, Arial, sans-serif;

    transition: all 0.4s $easeOutQuart;
    background: $white;
    border: 0.1rem solid $grey3;
    border-radius: 0.4rem;
    height: 5.6rem;

    &:disabled {
      background: $gray2;
      opacity: 0.6;
      -webkit-text-fill-color: $blue;
    }
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  .input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $white;
    color: $black;
    width: 100%;
    border: 0;
    padding: 1.6rem 1.6rem;
    font-size: 1.4rem;
    line-height: 1.8rem;

    transition: all 0.4s $easeOutQuart;
  }

  &.fullwidth {
    width: 100%;
  }

  &__legend {
    display: block;
    @extend %primary-xxs;
    padding: 0 0 $space-unit * 3;
    text-align: left;
    text-transform: none;
  }

  &__label {
    display: block;
    @extend %primary-xxs;
    position: absolute;
    font-size: 1.4rem;
    line-height: 1.8rem;

    left: 1.6rem;
    top: 1.5rem;
    transition: 0.2s ease all;
    text-align: left;
    text-transform: none;

    &:not(.spacer) {
      &:empty {

        // debugger for empty labels
        &::before {
          content: 'visually hidden label needed';
          color: royalblue;
        }
      }
    }

    &.spacerlabel {
      &::before {
        content: '\00a0'; // non breaking space
      }
    }
  }

  &__label-radio {
    width: 100%;
  }

  &__label--floating {
    position: absolute;
    left: 0.1rem;
    top: 0.1rem;
    right: 0.1rem;
    bottom: 0.1rem;
    background: $white;
    padding: 0 ($space-unit * 3);
    align-items: center;
    z-index: 2;
    pointer-events: none;
    @extend %primary-xs-u;
  }

  input:focus+&__label--floating {
    display: none;
  }

  &--cols {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &--gender-radio {
    display: flex;
    flex-wrap: wrap;

    .radio {
      margin-bottom: ($space-unit * 4);
      padding-right: ($space-unit * 8);
    }
  }

  &--inline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:focus-within {
      .form-group__label--floating {
        display: none;
      }
    }
  }

  &__helper-text {
    display: block;
    @extend %primary-xxs;
    margin: $space-unit 0 $space-unit * 3;
  }

  &--cols {
    .invalid-feedback {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .always-on {}
}

.invalid-feedback {
  color: $red;
  margin: 0.8rem 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  font-family: 'Satoshi-Regular', Arial, sans-serif;
  text-align: start;

  div {
    line-height: 1.6rem
  }

  &--float {
    position: absolute;
  }
}

@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}

.form-group,
.form-select {
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    @include fixedSprite('chevron-down');
    background-color: $white;
    height: 100%;
    background-position: calc(100% - 1.6rem) 50%;
    width: 100%;
    padding-right: 4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.alert {
  display: inline-block;
  margin: $space-unit * 4 0;

  &-danger {
    display: block;
    margin: 0 0 4.8rem 0;
    padding: 1.6rem;
    background: #BD0C0C08;
    border: 0.1rem solid #BD0C0C33;
    border-radius: 0.4rem;
    color: $red;

    p,
    span {
      color: $red;
    }
  }

  &--success {
    color: $status_success;
  }

  &--error {
    color: $status_error;
  }

  &--temp {
    animation: cssAnimation 0.5s $easeOutQuart 5s forwards;
    animation-fill-mode: forwards;
  }
}


.contactus-form> :last-child {
  align-items: flex-end;
}