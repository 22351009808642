header .menu-actions .search-menu-wrapper .search-container{ 
  .minitile-group {
    height: 41rem;
  }
}

.wrapper-search{
  height: 100vh;
  
  &:not(.active){
    pointer-events: none;
  }
}