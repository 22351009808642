
.feedbackbag{
  &__wrapper[data-visible=true] {

    .feedbackbag {
      right: unset;
      max-width: 90vw;
      top: unset;
      height: fit-content;
      bottom: 1.3rem;
      left: 1.2rem;

      &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;      
      }

      &__bottom-btn-wrapper {

        width: 100%;
        &-wrapper {
          display: flex;
          gap: 1.6rem;
          overflow: hidden;
          justify-content: space-between;
        }
      }
    }
  }

  &__overlay{
    display: flex;
    justify-content: center;  
  }
}

.collapse-translate .feedbackbag__wrapper[data-visible=true] #feedbackbag-container .feedbackbag__overlay{
  top: calc(7.2rem + var(--nav-wrapper));
  
  .feedbackbag {
    top: unset;    
  }

}

.nav-wrapper:not(.collapse-translate) .feedbackbag__wrapper[data-visible=true] #feedbackbag-container .feedbackbag__overlay .feedbackbag{
  top: unset;
}
