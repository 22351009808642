/**
 * @license
 *
 * Font Family: Gambarino
 * Designed by: Théo Guillard
 * URL: https://www.fontshare.com/fonts/gambarino
 * © 2023 Indian Type Foundry
 *
 * Font Style:
 * Gambarino Regular
 *
*/

@font-face {
  font-family: 'Gambarino-Regular';
  src: url('../fonts/Gambarino-Regular.woff2') format('woff2'),
    url('../fonts/Gambarino-Regular.woff') format('woff'),
    url('../fonts/Gambarino-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Regular
 * Satoshi Medium
 * Satoshi Bold
 *
*/

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../fonts/Satoshi-Regular.woff2') format('woff2'),
    url('../fonts/Satoshi-Regular.woff') format('woff'),
    url('../fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('../fonts/Satoshi-Medium.woff2') format('woff2'),
    url('../fonts/Satoshi-Medium.woff') format('woff'),
    url('../fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('../fonts/Satoshi-Bold.woff2') format('woff2'),
    url('../fonts/Satoshi-Bold.woff') format('woff'),
    url('../fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'EB Garamond';
  src: url('../fonts/EBGaramond-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bonheur Royale';
  src: url('../fonts/BonheurRoyale-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Graduate';
  src: url('../fonts/Graduate-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Valera Round';
  src: url('../fonts/VarelaRound-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('../fonts/BarlowCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: Satoshi-Medium, Arial, sans-serif;
  letter-spacing: 0.4px;
  -webkit-text-size-adjust: none;
}

.title-1 {
  font-size: 4rem;
  line-height: 4.8rem;

}

.title-2 {
  font-size: 3.2rem;
  line-height: 4rem;

}

.title-3 {
  font-size: 2.4rem;
  line-height: 3.2rem;

}

.title-4 {
  font-size: 2rem;
  line-height: 2.6rem;

}

.title-5 {
  font-size: 1.4rem;
  line-height: 2.4rem;

}

@include breakpoint(lg, max) {
  .title-sm-1 {
    font-size: 4rem;
    line-height: 4.8rem;

  }

  .title-sm-2 {
    font-size: 3.2rem;
    line-height: 4rem;

  }

  .title-sm-3 {
    font-size: 2.4rem;
    line-height: 3.2rem;

  }

  .title-sm-4 {
    font-size: 2rem;
    line-height: 2.6rem;

  }

  .title-sm-5 {
    font-size: 1.4rem;
    line-height: 2.4rem;

  }

  .par-sm {
    font-size: 1.4rem;
    line-height: 2rem;

    &-mini {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    &-small {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }

    &-mid {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &-big {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.pointer-cursor {
  cursor: pointer;
}

.no-click-events {
  pointer-events: none;
}

.all-click-events {
  pointer-events: all;
}

.par {
  font-size: 1.4rem;
  line-height: 2rem;

  &-mini {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  &-small {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  &-mid {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &-big {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.text-label {
  font-size: 1rem;
  line-height: 1.2rem;
  text-decoration: none;
  font-family: Satoshi-Medium, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.not-undeline,
.no-underline {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.text {
  &-bold {
    font-family: Satoshi-Bold, Helvetica, Arial, sans-serif;
  }

  &-light {
    font-family: Satoshi-Regular, Arial, sans-serif;
    font-weight: 400;
  }

  &-medium {
    font-family: Satoshi-Medium, Helvetica, Arial, sans-serif;
  }

  &-normal{
    font-weight: 500;
  }

  &-regular {
    font-family: Satoshi-Regular, Arial, sans-serif;
    font-weight: normal;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-black {
    color: $black;
  }

  &-white {
    color: $white;
  }
  
  &-gray6{
    color: $grey6;
  }  

  &-gray {
    color: $grey7;
  }

  &-gray8 {
    color: $grey8;
  }

  &-blue {
    color: $blue;
  }

  &-red {
    color: $red;
  }

  &-loyalty {
    color: $loyalty;
  }

  &-promo {
    color: $blue_promo;
  }
}

b {
  font-family: Satoshi-Bold, Helvetica, Arial, sans-serif;
}

.bold{
  font-weight: 700;
}

.inlineChild{
  display: block;
  
  a, span, p {
    display: inline;
  }
} 

h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-weight: normal;
  font-family: Satoshi-Medium, Helvetica, Arial, sans-serif;
  color: $blue;
}