#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.ot-close-btn-link {
      .ot-sdk-container {
        .ot-sdk-row {
          #onetrust-button-group-parent {
            #onetrust-button-group {
              button {
                padding: 0;
              }
            }
          }
        }
      }
      
    }
  }
}