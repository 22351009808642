header .menu-actions .search-menu-wrapper {
  .search-container {

    .search-field {
      background-color: $white;
      height: 5.6rem;

      &::after {
        left: 3rem;
      }
    }

    .results-container {
      width: 100%;
      border-top: initial;
      gap: 3.2rem;

      .view-all-suggestion-item {
        width: 100%;
        color: $grey7;
      }

      .submenu-recent-popular {
        flex-direction: column;

        .submenu-recent,
        .submenu-popular {
          width: 100%;
        }

        .submenu-popular {
          border-top: 0.1rem solid $grey3;
          padding-top: 3.2rem;
        }
      }

      .submenu-group {
        border: 0;

        &.minitile-group {
          border: 0;
        }

        ul {
          grid-template-columns: 100%;
          flex-wrap: nowrap;
          height: auto;
          gap: 2rem;
          padding-bottom: 0;

          li {
            grid-column-end: initial;
          }
        }

      }

    }

    &.open {
      .wrapper-search {
        opacity: 1;
      }
    }

    &:not(.open) {
      .wrapper-search {
        pointer-events: none;
        opacity: 0;
      }
    }

    .wrapper-search {
      position: fixed;
      z-index: 2;
      top: 0;
      border-radius: initial;
      overflow-y: auto;
      opacity: 1;
      transition: all 0.2s ease-out;

      .trap-search {
        height: 100%;
      }

      .icon--close {
        padding: 0 1rem 0 1.8rem;
        width: 4.8rem;
        height: 1.2rem;
        margin: 0 1.6rem 0 0.8rem;
      }
    }

    .minitile-group {
      gap: 1.2rem;
      grid-template-columns: 50% 50%;
    }

    .results-container a {
      flex-basis: 0%;
      padding: 0;

    }

    #searchResults .results-container {
      height: calc(100vh - var(--nav-wrapper));
      padding: 3.2rem 1.6rem;
      overflow: hidden auto;
      gap: 3.2rem;
      display: flex;
      flex-direction: column;
    }
  }
}

.error-500-wrapper {

  .title-icon-wrapper {

    .title {
      font-size: 18rem;
      line-height: 18rem;
      margin: 5.4rem 0;
    }
  }
}

#maincontent {
  .container-404 {
    .accordion__header {
      justify-content: center;
      gap: 1rem;
      padding: 0;
    }

    .accordion__header+.accordion__panel {
      margin: 1.2rem 0;
    }

    .accordion__header[aria-expanded=false]+.accordion__panel {
      border-bottom: 0;
    }

    .accordion__header[aria-expanded=true]+.accordion__panel {
      border-bottom: 0;
      margin: 2.4rem 0;
    }
  }
}

body {
  .container-404 {
    padding: 12rem 0;

    .title {
      font-size: 8rem;
      line-height: 8rem;
    }

    .text-wrapper {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .suggested-categories {
      padding: 0;
      justify-content: center;

      .accordion__header {
        justify-content: center;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .accordion__header[aria-expanded=false]+.accordion__panel {
        border-bottom: 0;
      }

      .accordion__header[aria-expanded=true]+.accordion__panel {
        margin: 2.4rem 0 1.3rem;
        border-bottom: 0;
      }
    }

    .product-carousel__carousel-container {
      margin-top: 12rem;
      transform: unset;

      .product-carousel__title {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
}