.minibag {
  display: flex;
  position: absolute;
  top: 6.4rem;
  right: 3.6rem;
  flex-direction: column;
  width: 40rem;
  max-height: 75vh;
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.3s ease-out;
  z-index: 1;
  background-color: $white;
  border-radius: 0.8rem;
  box-shadow: 0 1rem 2rem #00000010;

  &__content{

    padding: 2.4rem;
    display: flex;
    flex-direction: column;

    &-info-discount * {
      color: $blue;
    }

    &-info {
      &__approached {
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;
      }
    }

    &-product-recommended{
      padding-top: 0;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    &-wrapper{
      &--full{
       overflow-y: auto;        
      }

      &--empty{
        padding: 2.4rem;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        row-gap: 2.4rem;
      }
    }

    &--empty{
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.6rem;
    }
  }


  &.minibag-collapse {
    transform: translateY(8rem);
  }


  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-unit * 2;
    margin-bottom: 3.2rem;

    &--empty {
      display: flex;
      justify-content: space-between;
    }
  }

  .icon--close {
    position: absolute;
    top: 2.8rem;
    right: 2.4rem;
    z-index: 2;
  }

  &__mobile {
    padding-bottom: 1.4rem;
  }

  &__header {
    padding: 2rem 2.4rem;
    border-bottom: 0.1rem solid $grey3;

    &-wrapper{
      &--empty{
        position: absolute;
        width: 100%;
      }
    }
  }

  &__mobile-label {
    @include border('top');
    padding: 2rem 0 1.6rem 0;
  }

  &__buttons {
    @include animate;

    .button:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
  }


  &__product {
    display: flex;

    &-container{
      &:not(:last-child) {
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid $grey3;
        margin-bottom: 1.6rem;
      }      
    }

    &__personalization {
      padding: 0.8rem;
      border: 0.1rem solid $grey2;
      background-color: $grey1;
      border-radius: 0.4rem;
      display: flex;
      gap: 0.8rem;
      
      &__info {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    
        &-price {
          font-size: 1.3rem;
          line-height: 1.6rem;
        }
    
        &-details {
          display: flex;
          gap: 1.6rem;
          font-size: 1.2rem;
          line-height: 1.2rem;
          
          &-embrodery,
          &-color {
            gap: 0.4rem;
          }
        }
      }

      &__icon {
        padding: 0.6rem;
      }
    }

    &[data-offline-product='true'] {
      .minibag{
        &-outOfStock{
          color: $red;
          padding-top: 0.8rem;          
        }
        &__product{
          &__price,
          &__title .label-title,
          &__image {
            opacity: 0.3;
          }
        }
      }
    }

    &__image {
      flex-basis: 11rem;

      &-link {
        background-color: #e6e6e4;
        height: 14.4rem;
      }

      .product-image {
        height: 14.4rem;

        &.giftcard {
          flex-basis: 90%;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        &:before {
          content: initial;
        }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: $space-unit * 4;
      position: relative;
    }

    &__columns {
      display: flex;
      justify-content: space-between;

      .label-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__price {
      white-space: nowrap;
      margin-top: 0.6rem;
      
      .price {
        .sales[data-saletype='sale'],
        .sales[data-saletype='promotion'],
        .sales[data-saletype='sales'],
        .sales[data-saletype='employee'],
        .sales[data-promo-type='promo-dipendenti'] {
          padding: 0 0.2rem;
        }
      }
    }

    &__label {
      display: block;
      text-align: right;
      color: $black_secondary;
      line-height: 1.3;
    }

    &__info {
      margin-top: 1.6rem;
    }

    &__brand {
      padding-bottom: 0.6rem;
    }
  }

  &__bottom {
    &-wrapper {
      position: relative;
      bottom: 0;
      width: 100%;
      border-top: 0.1rem solid $grey3;
      background: $white;
      padding: 1.6rem 2.4rem 2.4rem;
    }

    &-btn{
      flex: 1;
      width: 100%;
      overflow: hidden;

      &:first-child:not(:last-child) {
        margin-right: 1.6rem;
      }

      &-wrapper{
        @include animate;
        display: flex;
        gap: 1.6rem;  
        
        &.hide {
          transition-delay: 0.2s;
          visibility: hidden;
        }
      }
    }

    &-total {
      display: flex;
      justify-content: space-between;
      padding-bottom: $space-unit * 4;
  
      &>* {
        text-transform: initial;
      }
    }

  }

  &__expresspayment {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: $white;
    top: 0;
    left: 0;

    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    @include animate;

    &.open {
      transition-delay: 0.2s;

      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    &__intro.button {
      width: 100%;
      padding-right: 1.6rem;
      padding-bottom: 0.8rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: capitalize;
    }

    #container-paywithgoogle {
      display: none;
    }

    .expresspayment-btn {
      display: block;

      &--applepay,
      &--applepay:hover {
        border-radius: 0;
        margin-top: 0;
      }
    }
  }
}

[data-react='minicart-app'] {
  .icon--bag {
    span {
      display: inline;
    }
  }
}

#cart-icon {
  &[aria-expanded='true'] {
    +#minibag-container {
      .minibag {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


.product-recommended{
  &__title{
    margin-bottom: 1.6rem;
  }

  &__content{
    display: grid;
    grid-template-columns: 16rem 16rem;
    gap: 0 1.6rem;
    max-width: 100%;
    width: 33.5rem;    
  }
}

.progress{
  &-feedback-discount,
  &-cart-discount,
  &-minibag-discount {
    height: 0.4rem;
    border-radius: 1rem;
    margin-top: 0.8rem;
    background-color: $green;
    width: 0;

    &-border {
      height: 0.4rem;
      border-radius: 1rem;
      width: 100%;
      margin-top: 0.8rem;
      background-color: $grey5;
    }
  }
}