#minibag-container {
  display: none;
  .minibag {
    top: 100%;
    width: 46%;
    padding: $space-unit * 4;
    padding-top: 2.2rem;

    &::after {
      top: 0;
    }
  }
}