.tooltip-persistent {
  &-modal {
    &--checkbox {
      left: -2.4rem;

      &::after {
        transform: translate(-50%, 1.1rem);
        left: 4rem;
      }

    }
  }
}
.login-modal {
  .tooltip-persistent {
    &-modal {
      &--checkbox {
        left: -0.8rem;

        &::after {
          left: 2.4rem;
        }

      }
    }    
  }
  
}
