.form-control {
  position: relative;

  input,
  textarea {

    &:disabled,
    &:read-only {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
      border-color: rgba($grey_input, $input-disabled-opacity);

      &+label {
        opacity: 0.3;
      }
    }

    &::placeholder {
      color: transparent;
      user-select: none;
    }

    &:focus {
      border-color: $blue;
    }

    &:-webkit-autofill~label,
    &:focus~label,
    &.label-up~label {
      top: 0.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &--outline {
    input {
      border: none;
      border-bottom: $space-line solid $input-outline;
      background-color: $white;
      border-radius: 0;
    }
  }
}

textarea.form-control {
  display: block;
  min-height: 12rem;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin-bottom: 0;
  border: 0.1rem solid $grey3;
  padding: 1.5rem 1.2rem;
  border-radius: 0.4rem;
  font-family: Satoshi-Medium,Arial,sans-serif;

  &:not(:placeholder-shown, :focus, :-webkit-autofill)+label {
    opacity: 0;
  }

  &:focus+label {
    opacity: 0;
  }

  &+label {
    position: absolute;
    top: 1.5rem;
    left: 1.6rem;
    color: #666;
    opacity: 1;

    transition: all 0.4s $easeOutQuart;
  }
}

.disable-resize {
  resize: none;
}

.valid-tick {
  content: '';
  right: $space-unit * 2;
  width: ($space-unit * 2);
  height: ($space-unit * 2);
  @include fixedSprite('checkmark');
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.is-valid {
  .valid-tick {
    display: inline-block;
  }
}

.form-button-container {
  display: flex;

  .button {
    margin-top: 0 !important;
  }
}

.show-password {
  position: absolute;
  top: 0;
  right: 1.2rem;
  bottom: 0;

  &.has-tick {
    right: ($space-unit * 8);
  }
}