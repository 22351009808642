.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  &.full {
    position: fixed;
    top: 0;
    left: 0;
    // width: 100vw; // allow use inside modals
    // height: 100vh;
    // background-color: $overlay;
    background: rgba(0,0,0,0.6);
    @include z(loader);
    .loader__dot {
      height: 1.2rem;
      width: 1.2rem;
      margin: 0 1.2rem;
    }
  }
  &__dot {
    height: 0.6rem;
    width: 0.6rem;
    margin: 0 0.5rem;
    animation: dot-keyframes 1.2s infinite ease-out;
    border-radius: 50%;
    background-color: $white;
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.8s;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.1;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.4);
  }

  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}
