.login-modal {
  .modal__body {
    max-height: unset;
    overflow: auto;
    form {
      .button-registration-section {
        margin-top: 3.2rem;

        h4 {
          padding-top: 3.2rem;
        }
      }
    }
  }
}

.cartCoupons{
  &__text__msg {
    flex-direction: column;  
    &__selected {
      font-family: Satoshi-Regular, Arial, sans-serif;
      font-weight: 400;
    } 
  }
}

.bag-login-slot-wrapper {
  padding: 1.6rem;

  .bag-login-slot {
    padding: 0;
  }

  .cart-loyalty-coupons-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.cartLoginWrapper {
  padding: 1.6rem;
  display: flex;

  .loginSlotButton{
    margin-right: unset;
  }

  .bag-login-slot {
    padding: 0;
  }

  .cart-loyalty-coupons-wrapper {
    flex-direction: column;
    align-items: center;
  }

  &-buttonWrapper{
    .button{
      padding: 0 1.2rem;
    }
  }
}

.newsletter-landing-page {
  .subscribe-to-newsletter-image {
    height: 100%;
    object-fit: unset;
    width: 100%;
    min-width: 100%;
    flex: 0 0 auto;
  }

  .subscribe-to-newsletter-titles {
    width: 100%;
    min-width: 100%;
    flex: 0 0 auto;

    .newsletter-form-wrapper {
      width: 100%;
      padding: 3.2rem 3.2rem 5.6rem;
    }
  }
}