.register_form {
  .phone-form {
    gap: 0.8rem;

    .prefix-phone {
      width: 23vw;
    }

    .input-phone {
      width: 67vw;
    }
  }

  .form-last-step {
    form {
      width: 100%;
    }
  }
}