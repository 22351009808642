// $background-width: calc(100% + #{$grid-gutter-width});

html {
  position: relative;

  &.debug-grid {
    &::after {
      @extend %primary-m-u;
      display: flex;
      content: '<xs';

      @each $breakpoint, $padding in $grid-breakpoints {
        @include breakpoint($breakpoint, min) {
          content: '#{$breakpoint}';
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      min-height: 100vh;
      content: '';

      pointer-events: none;
      @include z(grid-button);

      @each $breakpoint, $metrics in $grid-metrics {
        @include breakpoint($breakpoint, min) {
          width: calc(100% - (2 * #{map-get($metrics, 'container-padding')}));
          background-size: calc(100% + #{map-get($metrics, 'gutter')}) 100%;
          background-image: repeating-linear-gradient(to right,
              map-get($metrics, 'color'),
              map-get($metrics, 'color') map-get($metrics, 'overlay-columnwidth'),
              transparent map-get($metrics, 'gutter'),
              transparent map-get($metrics, 'overlay-repeatingwidth'));
        }
      }
    }
  }

  &::after {
    display: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: $space-unit * 12;
    height: $space-unit * 12;
    border-radius: 50%;
    bottom: $space-unit * 4;
    right: $space-unit * 4;
    font-size: 1.4rem;
    line-height: 1;
    box-shadow: rem 1rem 2rem rgba(71, 14, 14, 0.15);
    color: $white;
    text-transform: uppercase;
    @include z(grid-overlay);
  }
}