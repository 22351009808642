.product-recommended {
  &.in-pdp {
    width: 100%;
    margin: 0;
    background: initial;
    .product-recommended__title {
      word-wrap: break-word;
      margin-top: ($space-unit * 12);
      margin-bottom: ($space-unit * 8);
    }
  }

  &.full-cart{
    padding: 0 2.4rem;
    .product-recommended__content {
      .product-tile {
        width: calc(100% / 2 - 0.6rem);
      }
    }
  }

  &__content {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.2rem;

    .product-tile {
      width: calc(100% / 3 - 0.8rem);
    }
  }

  &.account {
    margin: 0;
    .product-recommended__title {
      margin: 0;
      padding-top: ($space-unit * 12);
      padding-bottom: ($space-unit * 8);
    }
  }
}
