.loader-ovs {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  z-index: 999999;
  opacity: 0.5;
  background: black;

  &:before {
    content: '';
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    display: inline-block;
    border-top: 0.3rem solid $white;
    border-right: 0.3rem solid transparent;
    box-sizing: border-box;
    animation: loaderOVS 1s linear infinite;
    position: absolute;
    top: calc(50% - 2.4rem);
    left: calc(50% - 2.4rem);
  }

  &-newsletter {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    z-index: 999999;
    opacity: 0.3;
    background: $blue;
  
    &__label {
      width: 7rem;
      height: 7rem;
      border: 0.2rem solid $white;
      border-radius: 100%;
      font-size: 0.8rem;
      line-height: 1.6rem;
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      align-content: center;
      text-align: center;
      position: absolute;
      top: calc(50% - 3.5rem);
      left: calc(50% - 3.5rem);
    }
  }
}

@keyframes loaderOVS {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}