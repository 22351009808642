.login-modal {
  .modal__body {
    form {
      .login {
        &__rememberMe{
          &-container {
            .toggle-switch-label {
              font-size: 1.3rem;
              line-height: 1.6rem;
              font-weight: 500;
              margin-left: 0.8rem;
              color: $blue;
              font-family: Satoshi-Medium, Helvetica, Arial, sans-serif;
            }
          }
        }

        &__notMe {
          &_link {
            color: $grey8;
          }
        }
      }
      
      .forgot-pw-error {
        .forgot-pw {
          padding: 1.6rem 0;
          height: unset;
          justify-content: center;

          span {
            border-bottom: 0.1rem solid $grey8;
          }
        }
      }

      .buttonRegistration {
        border-top: 0.1rem solid $grey3;

        &-title {
          font-size: 1.6rem;
          line-height: 2rem;
          padding-top: 3.2rem;
          padding-bottom: 0.8rem;
          text-align: center;
        }

        &-subtitle {
          font-size: 1.4rem;
          color: $grey7;
          text-align: center;
        }

        &-button {
          margin-top: 2.4rem;
        }
      }
    }
  }
}

.bag-login-slot-wrapper {
  background: $white;
  margin-bottom: 1.6rem;
  display: flex;
  width: 100%;
  border-radius: 0.4rem;
  padding: 2.4rem;
  flex-direction: column;
  gap: 1.6rem;
  min-height: 7rem;

  &.foreign{
    flex-direction: row;
  }

  .bag-login-slot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.4rem;
    align-items: center;
    gap: 1.6rem;

    .friends-heart-balance,
    .friends-status {
      padding: 0.8rem 1.2rem;
      border: 0.1rem solid $gray3;
      border-radius: 0.4rem;
      width: 100%;
      text-align: center;
      white-space: nowrap;
    }
  }

  .cart-loyalty-coupons-wrapper {
    padding: 1.2rem;
    background: $gray1;
    border-radius: 0.4rem;
    width: 100%;
  }
}

.cartCoupons{
  &Wrapper {
    background-color: $blue;
    border-radius: 0.4rem;    
  }
}

.cartLoginWrapper {
  background: $blue;
  padding: 1.6rem;
  display: grid;
  grid-template-columns: 3fr 5fr 2fr;
  width: 100%;
  border-radius: 0.4rem;
  gap: 1.6rem;
  min-height: 7rem;

  &-buttonWrapper{
    .button{
      cursor: default;
    }
  }

  &-wrapperText{
    align-self: center;

    p {
    color: $white;
    }
  }

  &>:first-child, &>:last-child{
    margin: auto;
  }

  &>:first-child{
    margin-bottom: -1.6rem;
  }

  .bag-login-slot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.4rem;
    align-items: center;
    gap: 1.6rem;

    .friends-heart-balance,
    .friends-status {
      padding: 0.8rem 1.2rem;
      border: 0.1rem solid $gray3;
      border-radius: 0.4rem;
      width: 100%;
      text-align: center;
      white-space: nowrap;
    }
  }

  .cart-loyalty-coupons-wrapper {
    padding: 1.2rem;
    background: $gray1;
    border-radius: 0.4rem;
    width: 100%;
  }
}

.loyalty-subscribe-modal {
  padding: 0;

  .modal__header {
    border-bottom: 0.1rem solid $grey3;
    padding: 2.4rem;

    h2 {
      padding: 0;
    }
  }

  .modal__body {
    padding: 2.4rem 2.4rem 5rem;
    overflow: auto;
    height: calc(100vh - 6.4rem - 9.6rem);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .modal-body-loyalty-section {

    .loyalty-become-friend-description {
      margin-bottom: 1.6rem;
    }

    .loyalty-data-protection {
      h5 {
        font-size: 1.3rem;
        line-height: 2rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    .newsletter-flag,
    .newsletter-flag-second,
    .newsletter-flag-third {
      margin-top: 1.6rem;

      input {
        height: unset;
        padding: 0;
        margin: 0;
      }

      .toggle-switch {
        gap: 4rem;
        align-items: flex-start;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .button-row {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: 0;
    padding: 0 2.4rem 4rem;
    left: 0;
  }
}

.newsletter-landing-page {
  .subscribe-to-newsletter-image {
    height: 100vh;
    object-fit: cover;
  }

  .subscribe-to-newsletter-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .newsletter-form-wrapper {
      width: 23.54vw;

      .section-titles {
        margin-bottom: 1.6rem;
        text-align: center;

        h2 {
          margin-bottom: 0.8rem;
          font-size: 2rem;
          line-height: 2.4rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }
      }

      #newsletter-otherfields {
        .checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 1.6rem;

          label {
            font-family: 'Satoshi-Regular';
            color: $grey7;
            font-size: 1.3rem;

            a {
              text-decoration: underline;
            }
          }
        }

        button {
          margin-top: 0.8rem;
        }
      }

      #newsletter-success-message {
        text-align: center;
        margin-top: 1.6rem;
      }
    }
  }
}