.mini-form {
  max-width: 100%;

  &.registration-complete {
    max-width: 100%;
  }
}
.form-group {
  input[type='date'][value='']:empty:not(:focus) {
    &:after {
      content: attr(placeholder);
      position: absolute;
      left: 0;
      min-height: 2rem;
      padding: 1.5rem 1.6rem;
      font-family: Satoshi-Regular, Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 2.2rem; 
    }

    &:before {
      content: '';
      @include fixedSprite('calendar');
      position: absolute;
      right: 1.6rem;
      top: 1.8rem;
    }

  }
}

input[type='search'] {

  &::-webkit-search-results-button,
  &::-webkit-search-cancel-button {
    display: none;
  }
}