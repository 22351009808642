.quickview-modal .tingle-modal-box {
  .tingle-modal-box__content {
    .product-quickview {
      .quickview-content {
        height: calc(100vh - 8.8rem);
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}