.overlay .open {
  nav {
    visibility: visible;
    background-color: $white;
  }
}

.hamburger-menu-pdp {
  display: none;
}

body {
  header {
    .nav-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }

    .checkout-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }
  }
}

body {
  header {
    .nav-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }

    .checkout-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }
  }
}


body {
  &.nav-white {
    header :not(.open) .nav-wrapper:not(.nav-scrolled):not(.expanded) {
      color: $white;
      position: relative;

      .menu-item a {
        color: $white;
      }

      .menu-actions .search-menu-wrapper .search-container {
        p.label {
          color: $white;
        }
      }

      .menu-item.open>.menu-heading::after {
        background-color: $white;
      }

      .nav-logo img {
        filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%);
      }

      .icon--account+span:not(.icon--check-filled) {
        filter: brightness(0) invert(1);
      }

      .menu-actions {
        .icon-- {

          &search,
          &account,
          &wishlist,
          &wishlist-old,
          &bag,
          &hamburger-2,
          &pin {
            &::after {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }

  &.nav-transparent {
    main {
      margin-top: 0;
    }
  }
}

header {
  .container-nav {
    padding: 0;
    height: 0;
    width: 100%;
  }

  .image-column {
    padding: 3.2rem 0 0;
  }

  .header-account-link {
    text-decoration: none;
    position: relative;
    order: 3;

    .header-account-banner {
      background-color: $blue;
      padding: 3.2rem 2.4rem;
      color: $white;
    }
  }

  .menu-actions {
    background: transparent;
    width: 100%;
    position: absolute;
    top: 0;
    justify-content: normal;
    padding: 0;
    // z-index: 1;

    .nav-link.logout-button {
      order: 3;
      position: absolute;
      right: 6.4rem;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu-icon {
      .account-menu-wrapper {
        .minidashboard {
          #account-icon.logged {
            &::before {
              content: '';
              @include fixedSprite('radio-check');
              position: absolute;
              bottom: 0.4rem;
              right: 0.4rem;
            }
          }
        }
      }

      .minibag-wrapper {
        span:not([class*='icon--']) {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-15%, -15%);
          border: 0.1rem solid $gray1;
        }
      }

      >button,
      >a {
        width: 4rem;
        height: 4rem;
      }

      &.hamburger-menu-wrapper {
        order: 1;
        margin: 0 0.8rem 0 1.6rem;
      }

      &.search-menu-wrapper {
        order: 2;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;

        .icon--search {
          margin: 0;
        }

        .search-container {

          button .label,
          .divider {
            display: none;
          }

          .icon--left::before {
            margin-right: 0;
          }
        }
      }

      &.account-menu-wrapper {
        order: 3;
        position: absolute;
        right: 6.4rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;

        .minidashboard {
          .love-icon {
            position: absolute;
            top: 1rem;
            right: -1rem;
          }
        }
      }

      &.minibag-menu-wrapper {
        order: 4;
        position: absolute;
        right: 1.6rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
      }
    }
  }

  .nav-wrapper {
    padding: 0;
    display: grid;
    height: 7.2rem;
    transform: translateY(0);
    border-bottom: 0 solid $gray3;

    &.nav-scrolled {
      height: 7.2rem;
      transition: background .3s ease-out, border 1.3s ease-out;
    }

    &:not(.collapse-translate) {
      .feedbackbag {
        bottom: 14rem !important;
      }
    }

    #mainnav {
      order: 2;
      width: 100%;
      padding: 0;
      max-width: unset;
      height: calc(var(--viewport-height) - 5.6rem - var(--ticker-height));
      flex-direction: column;
      margin-top: -1.6rem;
    }

    &.collapse-translate {
      transform: translateY(-7.2rem);

      #mainnav {
        height: calc(var(--viewport-height) - var(--nav-wrapper));
      }
    }
  }

  .nav-logo {
    order: 1;
    position: relative;
    height: 4rem;
    width: fit-content;
    margin: 1.6rem auto;
    z-index: 1;
    padding: 0;
    display: flex;
    align-items: center;

    &a {
      padding: 1.4rem;

      &svg {
        margin: auto;
        height: 3.2rem;
        width: auto;
      }
    }
  }

  .header-accordion {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 2rem;
    width: 100%;
  }

  .header-accordion::after {
    opacity: 1;
    visibility: visible;
  }

  .experience-component .experience-widget_1_editorial {
    width: 100%;
    margin-top: 3.2rem;
  }

  .header-accordion::after {
    content: "";
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%23222'%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d='M4.939 3L6 1.939 12.061 8 6 14.061 4.939 13l5-5z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") no-repeat;
    width: 1.7rem;
    height: 1.7rem;
    background-size: 1.7rem 1.7rem;
    display: inline-block;
    transition: transform .3s ease-out;
    transform: rotateZ(270deg);
  }

  .closed.header-accordion::after {
    transform: rotateZ(90deg);
  }

  .header-accordion.closed~ul {
    height: 0;
    opacity: 0;
    padding-bottom: 0;
  }

  #mainnav:not(:has(.wrapper-second-level .open, .wrapper-third-level .open)) .headerLinksComponent {
    border-top: 0;
    margin-top: 0;
  }

  .wrapper-second-level,
  .wrapper-third-level {
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;

    .close-btn {
      display: none;
    }

    &.open {
      margin: 2.4rem 0 4rem;
      padding: 0 2.4rem;
      height: auto;
    }


    .loyaltyLink {
      margin: 4rem 2.4rem 0;
      padding: 4rem 2.4rem 0;
      width: 100vw;
    }

    a {
      text-decoration: none;
      width: 100%;
      display: inline-block;

      &:has(+.label-category) {
        width: auto;
        margin-right: 0.8rem;
      }
    }

    .menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .second-level .menu-item {
      width: 100%;

      &:not(.no-subcategories)::after {
        content: '';
        @include fixedSprite('chevron-right');
        position: absolute;
        right: 0;
        z-index: -1;
      }
    }

    .menu-item {
      &:last-child:not(:first-child) {
        margin-top: 2rem;
        margin-bottom: 0;
      }

      &:first-child {
        margin-bottom: 2rem;
        margin-top: 0;
      }

      &:not(:first-child, :last-child) {
        margin: 2rem 0;
      }

    }
  }

  .wrapper-second-level {
    .menu-item.showAll {
      margin-bottom: 3.2rem;
      padding-bottom: 2.4rem;
      border-bottom: 0.1rem solid $gray3;
      width: 100%;
    }
  }

  .wrapper-third-level {
    .menu-item.showAll {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid $gray3;
    }
  }

  .overlay-submenu {
    display: none;
  }

  .headerLinksComponent {
    padding-top: 4rem;
    margin-top: 0;

    &-links {
      padding: 0 2.4rem;
    }
  }

  .loyaltyLink {
    padding: 4rem 2.4rem;
  }

  .wrapperHeaderLinks {
    width: 100%;
  }

  .menu-wrapper {
    position: relative;
    justify-content: flex-start;
    width: 100%;
    overflow-x: scroll;
    height: 5.6rem;
    min-height: 5.6rem;
    border-bottom: 0.1rem solid $gray3;
    border-width: 0.1rem 0;
    padding: 0 2rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .menu-item {
      text-decoration: none;
      white-space: nowrap;
      position: relative;
    }

    .divider {
      display: none;
    }

    &:has(.underline:not(.underline-default)) {
      .underline-default {
        &::after {
          content: none;
        }
      }
    }

    .underline,
    .underline-default {
      &::after {
        content: "";
        height: 0.2rem;
        position: absolute;
        bottom: -1.3rem;
        width: 100%;
        background: $blue;
        left: 0;
      }
    }
  }

  .menu-item button.menu-heading[aria-expanded="false"]+.wrapper-submenu .container-submenu {
    display: none;
  }

  .container-submenu {
    padding: 0 0 3.2rem;
  }

  .menu-footer {
    position: relative;
    order: 1;
    display: flex;
    overflow: visible;
    align-items: center;
    justify-content: space-around;
    background-color: $white;
    width: 100%;
    height: max-content;
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.08);
    top: 0;
    z-index: 1;

    .experience-component {
      width: 100%;
      margin-top: 0;
    }

    & .header--promo {
      padding: 3.2rem 2.4rem;

      &>div {
        display: block;
      }

      .header-promo-text {
        color: $light_orange;
        text-transform: uppercase;
      }

      & .promo-title {
        color: $light_orange;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
      }

      & .promo-description {
        color: $light_orange;
        text-transform: uppercase;
        margin-bottom: 2.4rem;
      }

      & .header-promo-code {
        color: $white;
        border: 0.2rem solid $white;
        margin-top: 2.4rem;
        margin-left: auto;
        cursor: pointer;

        & .CTA {
          margin: auto;
          padding: 1.2rem 3.2rem;
          text-transform: uppercase;
          width: fit-content;

          &::before {
            vertical-align: sub;
          }
        }
      }
    }

    .item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      text-decoration: none;

      .label {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.33;
        margin-top: 0.4rem;
      }
    }

  }

  .wrapper-submenu {
    padding: 0;
    overflow: auto;
    height: auto;
    position: relative;

    &.second-level,
    &.third-level {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      height: 0;

      &.open,
      .open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        height: auto;
        transform: translateX(0);
      }
    }
  }

  .visually-hidden-d {

    &,
    a {
      display: block;
      @include del-link;
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 300;
    }

    +.main-submenu>.submenu-group:nth-child(1)>.submenu-heading {
      margin-top: 4rem;
    }
  }

  .main-submenu {
    grid-template-columns: repeat(2, 47%);
    gap: 0 6%;
  }

  .navigation {

    .menu-wrapper,
    .menu-footer,
    .header-account-link,
    .headerLinksComponent,
    .wrapper-second-level,
    .wrapper-third-level,
    [data-menu-back] {
      @include animate(0.3);
      transition-property: opacity, visibility, transform;
      background-color: $white;
    }

    &.open {

      .menu-wrapper,
      .menu-footer,
      .header-account-link,
      .headerLinksComponent,
      .wrapper-second-level,
      .wrapper-third-level,
      [data-menu-back] {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }

    &:not(.open) {

      .menu-wrapper,
      .menu-footer,
      .header-account-link,
      .headerLinksComponent,
      .wrapper-second-level,
      .wrapper-third-level,
      [data-menu-back] {
        opacity: 0;
        visibility: hidden;
        transform: translateX(10rem);
      }
    }
  }

  .menu-heading {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.featured {
      color: $black;
    }

    &::after {
      content: '';
    }
  }

  button.text--content {

    transform: translateY(-2rem);
    color: $blue;
  }

  .third-level.open {
    .wrapper-back {
      padding-bottom: 3.2rem;
      display: block;
      margin-left: -0.6rem;
    }

    .menu-heading[aria-expanded='true'] {
      +.wrapper-submenu {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }

    .editorial-container {
      margin-top: 3.2rem;

      .treding-card {
        border: 0.1rem solid $gray3;
        border-radius: 0.4rem;
        margin: 1.2rem;
      }

      a {
        @include fixedSprite('chevron-right');
        display: flex;
        flex-direction: row;
        padding: 1.2rem;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 0.4rem;
        border: 0.1rem solid $grey3;
        background-position-y: center;
        background-position-x: calc(100% - 1.2rem);

        img {
          width: 10.7rem;
        }

        div {
          margin-right: 3.2rem;

          p:first-of-type {
            gap: 0.4rem;
            justify-content: flex-start;
            font-weight: 700;
          }
        }
      }
    }
  }

  .submenu-heading {
    margin-bottom: 0;
  }

  .submenu-group:not(.submenu-group-empty) {
    border-bottom: 0.1rem solid $grey6;
  }

  .submenu-group:is(:last-child) {
    border-bottom: 0.1rem solid $grey6;
  }

  .submenu-group {

    &+.submenu-group-empty {
      border-bottom: 0;
      border-top: 0;
      height: 0;
    }

    &.closed {
      border-bottom: 0.1rem solid $grey8;
    }

    ul {
      display: grid;
      gap: 3.2rem;
      padding-bottom: 3.2rem;

      li {
        margin-bottom: 0;
      }
    }
  }

  .wishlist-count,
  #cart-icon .cart-count {
    &:not([data-count='0']) {
      bottom: 0.4rem;
      right: 0.5rem;
      border: none;
    }
  }

  .navigation:not(.open) {
    .nav-wrapper {

      #mainnav,
      #mainnav * {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .navigation.open {
    .nav-wrapper {
      #mainnav {
        overflow-y: auto;
      }
    }
  }

  a.CTA.menu-heading:not([aria-expanded="true"])+div {
    display: none;
  }
}

[data-whatintent=keyboard] header .menu-item .menu-heading {

  &.underline:not(.notUnderline),
  &:focus {
    text-decoration: none;
  }
}