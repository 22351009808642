.personalization-modal {
  &.quickview-selectsize.modal.sizevariation-modal {
    .modal__body {
      .productDetails {
        &__body {
          &__header {
            &-wrapper {
              padding: 2.2rem 1.6rem;
            }
            &-btn {
              top: 50%;
              right: 1.6rem;
            }
          } 
          
          &__details {
            padding: 3.2rem 1.6rem 2.2rem;
            overflow: auto;
            .tooltip-quantity { 
              &-modal {
                right: 1.3rem;
                bottom: calc(100% + 0.5rem);
                &::after {
                  right: 0;
                }
              }
            }
            .size-container-section {
              flex-direction: column;  
              gap: 0.8rem;
              .counter {
                .btn__container {
                  width: 100%;
                }
              }    
              .selector-size-expanded {
                .selector__menu {
                  .size-selector-label-wrapper {
                    .size-default-wrapper {
                      display: flex;
                    }
                    
                  }
                  
                }
              }      
            }

            .personalization {
              &__accordion {
                &-container {
                  overflow: visible;
                  &:has(:not([aria-expanded='true'])) {
                    min-height: calc(var(--viewport-height) - 6.5rem - 3.2rem - 3.2rem - 12.8rem - 4rem - 2.2rem);                
                  }
                }
              }
              &__position {
                &__btn {
                  &-container {
                    padding-bottom: 4.6rem;
                    gap: 1.6rem
                  }
                }
              }
              &__embroidery {
                &-text {
                  &__btn {
                    padding-top: 0.8rem;
                    margin-left: auto;
                    font-size: 1.2rem;
                    line-height: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }    
  }

  &.personalization-images-modal {
    .modal__body {
      .personalizedImages {
        &__header {
          padding: 2.2rem 1.6rem;
          display: flex;
          gap: 2.4rem;
  
          &-btn {
            padding: 0;
            position: relative;
          }
        }
      }
    }
  }

  .productDetails__img{
    &-node {
      .imgEmbroideryText {
        font-size: 1rem;
      }      
    }
  }

}