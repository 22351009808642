#account-registerphone #maincontent,
#account-registercomplete #maincontent,
#account-otp #maincontent,
#account-otpphone #maincontent,
#account-thankyouregister #maincontent {
  background-color: $grey3;
  min-height: calc(100vh - 8.8rem - 8.8rem);
}

.register-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.4rem 0;
}

.register-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 0;
  background-color: $white;
  position: relative;
}

.register_form {
  text-align: center;

  .form-last-step {
    form {
      width: 28.89vw;
    }
  }

  &__info {
    &__text {
      text-align: left;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1.6rem;
      padding-bottom: 0.8rem;
    }
    &__gender {

      .radio-wrapper {
        display: flex;
        gap: 0.8rem;  
        position: relative;

        .form-group {
          position: unset;
          flex-basis: unset;
          max-width: unset;

          &:has(.invalid-feedback) {
            padding-bottom: 2.4rem;
          }

          .invalid-feedback {
            position: absolute;
            left: 0;
          }   
          
          .radio input {
            align-self: baseline;
          }
        } 
        
        .radio {
          border-radius: 0.4rem;
          border: 0.1rem solid $grey3;
          background-color: $white;
          padding: 0.8rem 1.2rem;
          width: fit-content;

          label {
            span {
              font-family: Satoshi-Regular, Arial, sans-serif;
              font-weight: 400;              
            }
          }

          input {
            display: none;
          }

          &:has(input:checked) {        
            border-color: $blue;
            background-color: $blue;

            label {
              span {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .phone-form {
    div[class^="col-"]:not([class*="-12"]):not(:first-child):last-child {
      padding-left: 1.6rem;
    }

    .prefix-phone {
      width: 6.25vw;
    }

    .input-phone {
      width: 22.083vw;
    }
  }

  .social-row {
    margin-left: 0.1rem;
    margin-bottom: ($space-unit * 6);

    .social-button {
      height: ($space-unit * 4);

      .icon {
        height: 1.4rem;
        width: 1.4rem;
        margin-right: ($space-unit * 2);
      }

      .google {
        @include fixedSprite('social-google');
      }

      .facebook {
        @include fixedSprite('social-facebook');
        margin-left: ($space-unit * 6);
      }
    }
  }

  .gendercol .form-group {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;

    .form-group__label {
      @include col-width(12);
    }

    .radio {
      &:nth-child(even) {
        @include col-width(4);
      }

      &:nth-child(odd) {
        @include col-width(8);
      }
    }
  }

  .channel-flags {
    padding-left: $space-unit * 4;
  }

  .otpresend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.registration-legal-box .radio-wrapper {
  display: flex;
  gap: 7.5rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  @include fixedSprite('calendar');
  cursor: pointer;
  transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input:not(:placeholder-shown, [type="radio"], :-webkit-autofill):is(:focus):is([type="date"])::-webkit-calendar-picker-indicator {
  transform: translateY(-0.9rem);
}

.registration-legal-box {
  background-color: $white;
  border: 0.1rem solid $grey6;
  border-radius: 0.4rem;
  padding: 2.4rem;
  max-width: 67rem;
  text-align: initial;
}

.mini-form .row {
  justify-content: center;
}