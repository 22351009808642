[data-whatintent='touch'] {
  header {
    .wrapper-submenu {
      transition: none;
    }
  }
}

#login-show,
#gift-show {
  background: $blue;
}

body {
  header {
    .nav-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }

    .checkout-logo img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%) !important;
    }
  }
}

body {

  &.nav-white {

    header .nav-wrapper:not(.expanded):not(.nav-scrolled) .menu-wrapper:has(.underline):not(.expanded):not(.nav-scrolled) .menu-item:not(.open) .menu-heading:not(.underline),
    header .nav-wrapper:not(.expanded):not(.nav-scrolled) .menu-wrapper:has(.underline) .menu-item:not(.open) .menu-heading.underline.notUnderline {
      color: $white;
    }

    header .nav-wrapper:not(.expanded):not(.nav-scrolled) .menu-item.open>.menu-heading::after,
    header .nav-wrapper:not(.expanded):not(.nav-scrolled) .menu-item:has(.CTA.menu-heading.underline:not(.notUnderline))>.menu-heading::after {
      background-color: $white;
    }

    header .nav-wrapper:not(.nav-scrolled):not(.expanded) {
      color: $white;
      position: relative;
      border-bottom: 0 solid $gray3;

      .menu-item a {
        color: $white;
      }

      .menu-actions .search-menu-wrapper .search-container {
        p.label {
          color: $white;
        }
      }

      .menu-item.open>.menu-heading::after {
        background-color: $white;
      }

      .profileName,
      .user-message {
        color: $white;
      }

      .nav-logo img {
        filter: brightness(0) saturate(100%) invert(77%) sepia(75%) saturate(3194%) hue-rotate(359deg) brightness(112%) contrast(97%);
      }

      .icon--account+span:not(.icon--check-filled) {
        filter: brightness(0) invert(1);
      }

      .divider {
        background-color: $white;
      }

      .menu-actions {
        .icon-- {

          &search,
          &account,
          &wishlist,
          &wishlist-old,
          &bag,
          &pin {
            &::after {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }

  &.nav-transparent {
    main {
      margin-top: 0;
    }
  }
}

header {
  .nav-wrapper {
    position: relative;
    top: 0;
    transition-property: transform, background;
    height: 7.2rem;
    border: 0 solid $gray;

    .menu-actions {
      margin-left: auto;
    }

    .second-level-menu {
      &-wrapper {
        opacity: 0;
        visibility: hidden;
        height: calc(100vh - var(--nav-wrapper));
        position: absolute;
        top: 100%;
        left: 0;
        background: white;
        width: 25%; 
      }

      &-scrollable {
        max-height: calc(100vh - var(--nav-wrapper));
      }

      &__footer {
        padding: 0 2.4rem 3.2rem;
      }
    }

    &.expanded {
      box-shadow: initial;

      .overlay-submenu {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;
        display: block;
        opacity: 1;
        pointer-events: all;
      }

      .second-level-menu {
        &-wrapper {
          opacity: 1;
          visibility: visible;               
        }
      }
    }

    .close-btn {
      top: 1.6rem;
      position: absolute;
      right: calc((3.2rem + 1.6rem) * -1);
      cursor: pointer;
      background-color: #00152630;
      padding: 0.6rem;
      border-radius: 3rem;

      &:hover {
        background-color: $blue;
        cursor: pointer;
      }

      &::after {
        filter: brightness(0) invert(1);
      }
    }

    .hamburger-menu-pdp {
      display: none;
    }

    .menu-icon.hamburger-menu-wrapper {
      display: none;
    }

    .overlay-submenu {
      display: none;
      opacity: 0;
      pointer-events: none;
    }

    &.collapsing {
      pointer-events: none;
    }

    &.collapse {
      .menu-item {
        display: none;
      }

      .icon--menu {
        display: flex;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .menu-actions {
      .account-menu-wrapper {
        .minidashboard {
          position: relative;

          div {
            @include animate();
          }

          &-wrapper {
            width: 23.2rem;

            li {
              a {
                padding: 1.4rem 1.6rem;
                border-bottom: 0.1rem solid $gray3;
                text-decoration: none;
                display: block;
                line-height: 2rem;
                color: $blue;
                background-color: $white;
                @include animate();

                &:hover {
                  background-color: $gray2;
                }
              }
            }
          }

          .closed {
            opacity: 0;
            pointer-events: none;
          }

          .open {
            opacity: 1;
            pointer-events: all;
          }

          #minidashboard-container {
            position: absolute;
            top: 3rem;
            right: 50%;
            transform: translateX(50%);
            box-shadow: 0 1rem 2rem 0 #0000001A;
            border-radius: 0.8rem;
            background: $white;
            overflow: hidden;
          }

          .loginLayerWrapper {
            position: absolute;
            right: -100%;
            width: 30rem;
            top: calc(100% + 1.2rem);
            background: $white;
            border-radius: 0.8rem;
            box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);

            &-loyaltyMessage {
              margin-top: 3.2rem;
              padding: 2.4rem;
              background-color: $gray2;

              &:not(.logged) {
                border-radius: 0 0 0.8rem 0.8rem;
              }

              &.logged {
                margin: 0;
                background: $blue;
              }
            }

            &-title,
            &-buttons {
              margin: 2.4rem;
            }
          }
        }
      }

      .pin-menu-wrapper {
        .divider {
          height: 1.2rem;
          width: 0.1rem;
          margin: 0 1.6rem;
          background: $gray5;
        }
      }
    }

    .menu-heading {
      position: relative;
      display: flex;
      align-items: center;
    }

    .divider {
      height: 1.2rem;
      width: 0.2rem;
      margin: 0 .4rem .4rem .4rem;
      background: $gray5;
    }

    .menu-item {
      white-space: nowrap;

      &.open,
      &:has(.CTA.menu-heading.underline:not(.notUnderline)) {
        >.menu-heading::after {
          content: "";
          position: absolute;
          height: 0.2rem;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: $blue;
        }
      }
    }

    .wrapper-submenu {
      border-radius: 0;
      top: 100%;
      pointer-events: none;

      &.third-level {
        left: 25%;
        width: 50%;
        background-color: $gray1;
        height: calc(100vh - var(--nav-wrapper));

        &:has(.menu-item:hover) {
          .menu-item {
            &:hover {
              .menu-subheading {
                text-decoration: underline;
                text-decoration-color: $blue;
              }
            }

            &:not(:hover) {
              .menu-subheading {
                color: $gray7;
              }
            }
          }
        }

        .editorial-container {
          img {
            width: 100%;
          }
        }
      }

      &.second-level {
        position: relative;
        width: 100%;
        display: none;

        &.open {
          display: block;
        }

        .main-submenu {
          max-height: unset;
          overflow: unset;
          padding-bottom: 0;
        }
      }

      &.second-level,
      &.third-level {
        pointer-events: none;
        border-top: 0.1rem solid $gray3;

        .menu-item {
          margin: 1.2rem;
        }

        &.open {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          overflow: visible;
        }

        &:has(.menu-item.open) {
          .menu-item {
            &.open .menu-subheading {
              text-decoration: underline;
              text-decoration-color: $blue;
            }

            &:not(.open) {
              .menu-subheading {
                color: $gray7;
              }
            }
          }
        }
      }

      .main-submenu {
        padding: 3.2rem 2.4rem;
        max-height: calc(100vh - var(--nav-wrapper));
        overflow: auto;

        .menu-item {
          @include animate();
          margin: 1.2rem 0;
          padding: 0;
          width: fit-content;

          &.open a {
            text-decoration: underline;
            text-decoration-color: $blue;
          }

          &:first-child {
            margin-top: 0;
          }

          &.showAll {
            margin-bottom: 3.2rem;
            padding-bottom: 2.4rem;
            border-bottom: 0.1rem solid $gray3;
            width: 90%;
          }

          a {
            text-decoration: none;
          }
        }

      }
    }

    .menu-footer {
      border-radius: 0;
      transform: translateY(-3.2rem);
      top: -3.2rem;
      position: absolute;
      width: 100%;
      left: 0;
      top: 47.6rem;

      &.open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        pointer-events: all;
      }

      & .header--promo {
        padding: 1.2rem 4rem;

        & .promo-title {
          color: $light_orange;
          text-transform: uppercase;
        }

        & .promo-description {
          color: $light_orange;
          text-transform: uppercase;
        }

        & .header-promo-code {
          color: $white;
          border: 0.2rem solid $white;
          margin-left: auto;
          cursor: pointer;

          & .CTA {
            margin: auto;
            padding: 1.2rem 3.2rem;
            text-transform: uppercase;

            &::before {
              vertical-align: sub;
            }
          }
        }
      }
    }

    .submenu-group-empty {
      margin-top: 4rem;
    }
  }
}

#product-show header {
  #mainnav {
    &:not(.open) {
      @include animate();
      transform: translateX(1.6rem);
      opacity: 0;
    }

    &.open {
      opacity: 1;
    }
  }

  .hamburger-menu-pdp {
    display: block;
    @include animate();
    width: 2rem;
    opacity: 1;

    &.clicked {
      width: 0;
      margin: 0;
      opacity: 0;
    }

    button {
      &::after {
        @include animate();
        opacity: 1;
        transform: scaleY(1);
      }

      &::before {
        content: '';
        @include animate();
        opacity: 0;
        transform: scaleY(0.5);
        @include fixedSprite('hamburger-3');
        position: absolute;
      }

      &:hover {
        &::after {
          opacity: 0;
        }

        &::before {
          opacity: 1;
          transform: scaleY(1);
        }
      }
    }

    +nav {
      display: none;
    }
  }


  .nav-wrapper.nav-scrolled:not(.expanded) {
    background-color: $white;
    border-bottom: 0.1rem solid $gray;
  }
}