.order-detail-page-return {
  .order-footer {
    grid-template-columns: 1fr 1fr;

    .order-footer__tracking {
      grid-column-start: 1;
      grid-column-end: 3;
      margin-top: 1rem;
    }
  }
}

.order-items .order-item__image {
  max-width: 10rem;
}