// container
.radio {
  display: flex;
  break-inside: avoid-column;
  position: relative;
  align-items: center;

  &:disabled {
    opacity: 0.3;
  }

  input[type='radio'] {
    width: $radio-size;
    height: $radio-size;
    border-radius: 100%;
    margin: 0 1.6rem 0 0;
    top: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    flex-shrink: 0;
    padding: 0;

    &:disabled {
      opacity: 0.3;

      &+label {
        opacity: 0.3;
      }
    }

    &+label {
      &:disabled {
        opacity: 0.3;
      }
    }

    [data-whatintent='keyboard'] & {
      &:focus {
        outline: none;

        +label {
          &::before {
            // focus visible
            box-shadow: 0 0 0 $outline-width $outline-color;

            @media (-webkit-min-device-pixel-ratio: 2) {
              box-shadow: 0 0 0 calc(#{$outline-width}/ 2) $outline-color;
            }
          }
        }
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  input[type='radio']+label {
    @extend %primary-xs;
    color: $blue;
    display: flex;
    cursor: pointer;
    position: relative;
    width: 100%;
    align-items: center;

    &::first-letter {
      text-transform: uppercase;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  input[type='radio']::after {
    content: '';
    @include fixedSprite('radio-on');
    position: absolute;
    transition: transform 0.4s cubic-bezier(0.6, 0, 0.2, 1);

    &:disabled {
      opacity: 0.3;
    }
  }

  &.radio-full input[type='radio']::after {
    content: '';
    @include fixedSprite('radio');
  }

  &.radio-check input[type='radio']::after {
    content: '';
    @include fixedSprite('radio-check');
  }

  input[type='radio']::before {
    content: '';
    @include fixedSprite('radio');
    position: absolute;
    transition: transform 0.4s cubic-bezier(0.6, 0, 0.2, 1);

    &:disabled {
      opacity: 0.3;
    }
  }

  input[type='radio']:checked {
    &::after {
      transform: scale(1);
    }

    &::before {
      transform: scale(0);
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  input[type='radio']:not(:checked) {
    &::after {
      transform: scale(0);
    }

    &::before {
      transform: scale(1);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    @extend %primary-s;

    [class^='icon'] {
      margin-right: ($space-unit * 2);
    }

    >span {
      display: flex;
    }
  }

  &__icon--payment {
    display: flex;
    align-items: center;

    [class^='icon'] {
      width: 3.2rem;
      height: 2.4rem;
      margin-right: ($space-unit * 4);
    }

    .icon--payment-card,
    .icon--payment-cashondelivery {
      background-size: cover;
    }
  }

  &--outline {
    border: $space-line solid $blue;
    padding: ($space-unit * 4) 1rem;
    margin-bottom: $space-unit;
    min-height: 5rem;
    align-items: baseline;

    input[type='radio'] {
      top: 0.2rem;

      +label {
        &::before {
          // focus visible
          left: -1.9rem;
          top: -0.3rem;
        }
      }
    }
  }
}

.boxed-radio {
  .radio {
    margin-bottom: 0;
    // padding: 2.4rem;
    border: solid 0.1rem $white;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    @include rounded-box;
    @include animate;

    input[type='radio'] {
      margin: auto 2.4rem;

      &:checked {
        background-color: $blue;

        &::after {
          @include fixedSprite('checkmark-radio');
        }
      }

      +label {
        padding: 2.4rem;
        // padding-left: 0;
        width: auto;
        flex-grow: 1;

        &::before {
          content: initial;
        }
      }
    }

    &.selected {
      box-shadow: 0 0.2rem 0 0 rgba(0, 0, 0, 0);
      border: solid 0.1rem $blue;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}

.is-invalid {
  .radio {
    color: $status_error;
  }
}