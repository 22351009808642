.footer {
  .accordion__group {
    text-align: left;
    position: relative;

    .accordion__item {
      &:last-child {
        @include border('bottom');

        .accordion__header {
          border-bottom: initial;
        }
      }
    }
  }

  .accordion__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: $blue;
    padding: 2rem 0;
    width: 100%;
    font-size: 1.4rem;

    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

    &>span {
      text-align: left;
    }

    &:focus {
      outline: transparent;

      .header__open,
      .header__close {
        [data-whatintent='keyboard'] & {
          outline: $outline-width solid $outline-color;
        }
      }
    }

    &--uc {
      &>span {
        @extend %primary-xs-u;
      }
    }

    &--lc {
      &>span {
        @extend %primary-s;
      }
    }

    .header__open,
    .header__close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      min-width: ($space-unit * 6);
      min-height: ($space-unit * 6);
      width: auto;
      height: auto;
      display: none;
      align-items: center;

      .text {
        @extend %primary-xs;
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: ($space-unit * 6);
        height: ($space-unit * 6);
      }
    }

    &--cta-text {
      &>span {
        width: calc(100% - 5.4rem);
      }

      .header__open,
      .header__close {
        .text {
          display: block;
        }

        .text,
        &::after {
          opacity: 0.55;
        }
      }
    }

    &::after {
      content: '';
      @include fixedSprite('chevron-down');
      margin-left: 0.4rem;
      transition: transform 0.3s ease-out;
    }

    &[aria-expanded='false'] {
      .header__open {
        display: flex;
      }

      &::after {
        transform: rotate(0);
      }

      &+.accordion__panel {
        max-height: 0 !important;
        border-bottom: 0.1rem solid $gray3;
      }
    }

    &[aria-expanded='true'] {
      &+.accordion__panel {
        height: fit-content;
      }

      &::after {
        transform: rotate(180deg);
      }

      .header__close {
        display: flex;
      }
    }

    &--payments {
      align-items: flex-start;

      .header__open,
      .header__close {
        top: 1rem;
        transform: initial;
      }

      >span {
        white-space: initial;
        line-height: 1.6;
      }
    }
  }

  .accordion__panel {
    overflow: hidden;
    visibility: visible;
    transition: all 0.3s ease-out;
    opacity: 0.8;
    border-bottom: 0.1rem solid $white;

    // @include animate;
    >* {
      padding-top: $space-unit;
    }

    &--grenoble {
      text-transform: uppercase;
    }
  }
}

body {
  &#product-show {
    #maincontent {
      .accordion__panel {
        padding: 0 1.2rem;

        .info-wrapper-container {

          .info-wrapper {
            padding-bottom: 0.8rem;
          }
        }

        .info-wrapper-container-top {
          padding-top: 2.4rem;
          border-top: 0.1rem solid $grey3;
        }

        .product-info-details {
          display: flex;
          gap: 2rem;
          padding-bottom: 2.4rem;
          align-items: center;

          &:last-child {
            padding-bottom: 0.8rem;
          }
        }

        .eco-value-wrapper {
          align-items: center;
          gap: 2.4rem;
          background: rgba(62, 125, 57, 0.03);
          border: 0.1rem solid rgba(62, 125, 57, 0.2);
          border-radius: 0.4rem;
          padding: 1.6rem;
          margin-bottom: 0.8rem;

          &.eco-recycle {
            margin-bottom: 0;
            border-radius: 0.4rem 0.4rem 0 0;
          }

          .row {
            flex: 1 1 0;
            width: 100%;

            p {
              width: 100%;

              &.ecoCircolaritaInfo {
                flex: 1 0 0;
              }
            }
          }

          .eco-icon {
            min-width: 2.2rem;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.container-ecoCircolarita {
            gap: 0;
            border-top: 0;
            border-radius: 0 0 0.4rem 0.4rem;
            background: $white;

            .icon--leaf-full,
            .icon--leaf-empty {
              padding-right: 1.5rem;

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }

        .eco-info-label {
          color: $grey8;
          padding-top: 1.6rem;
        }

        .more-details-wrapper {
          justify-content: space-between;
          margin-top: 1.6rem;
          margin-bottom: 0.8rem;

          a {
            font-size: 1.3rem;
            color: $blue;
            text-decoration: none;
            border-bottom: 0.1rem solid $blue;
          }
        }
      }

      .accordion__header {
        &[aria-expanded='true'] {
          &+.accordion__panel {
            padding: 0 1.2rem 1.6rem;
          }
        }
      }
    }
  }
}

body {
  &#accountapi-loyaltylanding {
    #maincontent {
      .accordion__header {
        &[aria-expanded='true'] {
          &+.accordion__panel {
            padding: 0 2.4rem 2.4rem;
          }
        }
      }
    }
  }
}

#maincontent,
#checkout-main {
  .accordion__group {
    text-align: left;
    position: relative;

    .accordion__item {
      &:last-child {
        @include border('bottom');

        .accordion__header {
          border-bottom: initial;
        }
      }
    }
  }

  .my_accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &::after {
      content: '';
      @include fixedSprite('chevron-down');
      margin-left: 0.4rem;
      transition: transform 0.3s ease-out;
    }

    &[aria-expanded='false'] {
      &::after {
        transform: rotate(0);
      }
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .my_accordion__panel,
  .accordion__panel {
    overflow: hidden;
    visibility: visible;
    transition: all 0.3s ease-out;
  }

  .accordion-custom {
    .accordion__header {
      padding: 1.4rem 1.2rem;
    }
  }

  .accordion__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $black;
    padding: 2.4rem;
    width: 100%;
    font-size: 1.4rem;
    line-height: 2rem;


    &.product-info__item {
      padding: 1.4rem 1.2rem;

      &.eco-recycle-wrapper {
        h2 {
          color: $green;
          margin-left: 0.8rem;
        }
      }
    }

    &.order-details-more-info {
      padding: 1.6rem 0;
      margin: 0 0 2.4rem;
      border-bottom: 0.1rem solid $grey3;

      &[aria-expanded='true'] {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    &.order-history-accordion {
      justify-content: flex-end;
      padding: 0 0 0.8rem;
    }
  }

  .accordion__panel {
    padding: 0 2.4rem;

    &.order-details-more-info-body,
    &.order-history-accordion-panel {
      padding: 0;
    }
  }

  .accordion__header {
    &>span {
      text-align: left;
    }

    &:focus {
      outline: transparent;

      .header__open,
      .header__close {
        [data-whatintent='keyboard'] & {
          outline: $outline-width solid $outline-color;
        }
      }
    }

    &--uc {
      &>span {
        @extend %primary-xs-u;
      }
    }

    &--lc {
      &>span {
        @extend %primary-s;
      }
    }

    .header__open,
    .header__close {
      position: absolute;
      right: -0.8rem;
      top: 50%;
      transform: translateY(-50%);
      min-width: ($space-unit * 6);
      min-height: ($space-unit * 6);
      width: auto;
      height: auto;
      display: none;
      align-items: center;

      .text {
        @extend %primary-xs;
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: ($space-unit * 6);
        height: ($space-unit * 6);
      }
    }

    &--cta-text {
      &>span {
        width: calc(100% - 5.4rem);
      }

      .header__open,
      .header__close {
        .text {
          display: block;
        }

        .text,
        &::after {
          opacity: 0.55;
        }
      }
    }

    &::after {
      content: '';
      @include fixedSprite('chevron-down');
      margin-left: 0.4rem;
      transition: transform 0.3s ease-out;
    }

    &[aria-expanded='false'] {
      .header__open {
        display: flex;
      }

      &::after {
        transform: rotate(0);
      }

      &+.accordion__panel {
        max-height: 0 !important;
        border-bottom: 0.1rem solid $grey3;

        &.order-details-more-info-body,
        &.order-history-accordion-panel {
          border-bottom: 0;
        }
      }
    }

    .product-info__item--content:before {
      width: 1.8rem;
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(180deg);
      }

      .header__close {
        display: flex;
      }

      &+.accordion__panel {
        border-bottom: 0.1rem solid $grey3;

        &.order-details-more-info-body {
          padding-bottom: 1.6rem;
          margin-bottom: 1.6rem;
        }

        &.order-history-accordion-panel {
          padding: 0;
          border-bottom: 0;
        }
      }
    }

    &.filter-horizontal {
      display: flex;
      align-items: center;

      &::after {
        content: '';
        @include fixedSprite('chevron-up');
        transition: transform 0.3s ease-out;
      }

      &[aria-expanded='false'] {
        &::after {
          transform: rotate(-180deg);
        }

        &+.accordion__panel {
          border-bottom: 0 !important;
        }
      }

      &[aria-expanded='true'] {
        &::after {
          transform: rotate(0);
        }
      }
    }

    &--payments {
      align-items: flex-start;

      .header__open,
      .header__close {
        top: 1rem;
        transform: initial;
      }

      >span {
        white-space: initial;
        line-height: 1.6;
      }
    }
  }

  .refinements-horizontal .filters-container {
    gap: 1.6rem;
    margin-right: 3.2rem;

    .accordion__header {
      padding: 2.2rem 0;
      margin: 0;
    }

    .accordion__panel {
      border-right: 0.1rem solid $grey3;
      border-bottom: 0.1rem solid $grey3;
    }
  }

  .refinements .filters-container {
    button {
      .toggle-switch {
        width: 100%;
        padding: 2.2rem 2.4rem;
        margin: 0;
        border-bottom: 0.1rem solid $grey3;
      }
    }
  }

  .accordion__panel {
    overflow: hidden;
    visibility: visible;
    transition: all 0.3s ease-out;

    &--sort {
      overflow-x: auto;
      display: flex;
      flex-direction: row;
      padding: 0;
      align-items: center;
      margin-top: 2.4rem;
      padding-bottom: 0.4rem;

      &-btn {
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8rem 1.2rem;
        border: 1px solid $gray;
        border-radius: 0.4rem;
        transition: all 0.1s ease-out;
        margin-right: 0.8rem;
        min-width: fit-content;

        &:hover {
          border-color: $blue;
        }

        &.selected {
          background-color: $blue;
          border-color: $blue;

          span {
            color: $white;
          }
        }
      }
    }

    &--grenoble {
      text-transform: uppercase;
    }
  }

  .modal-isml:not(#filters-modal) .accordion__panel>ul>li:is(:last-child) {
    padding-bottom: 2rem;
  }
}

.personalization-modal {

  .accordion {
    &__header {
      position: relative;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: $blue;
      padding: 1.4rem 1.2rem;
      width: 100%;
      font-size: 1.4rem;
      line-height: 2rem;

      &.disabled {
        opacity: 0.6;
        cursor: none;
        pointer-events: none;
      }

      &__info {
        line-height: 1.9rem;
        font-size: 1.3rem;
      }

      &::after {
        content: '';
        @include fixedSprite('chevron-up');
        transition: transform 0.3s ease-out;
      }    

      &[aria-expanded='false'] {
        &::after {
          transform: rotate(-180deg);
        }

        &+.accordion__panel {
          max-height: 0 !important;
        }
      }

      &[aria-expanded='true'] {
        &::after {
          transform: rotate(0);
        }

        .accordion__header__info {
          display: none;
        }
      } 
      
      &__title {
        font-size: 1.3rem;
        line-height: 1.9rem;

        &_selected {
          &_position,
          &_personalization {
            &-info {
              font-size: 1.1rem;
              line-height: 1.9rem;   
              color: $grey6;       
            }  
            &-dot {
              font-size: 1.3rem;
              line-height: 1.9rem;
            }            
          }

          &_personalization {
            @include text-shorten;
            max-width: 14rem;
          }

          &_position {
            @include text-shorten;
            max-width: 14rem;
          }
        }
      }
      
    }

    &__panel {
      padding: 0 1.2rem;
      overflow: hidden;
      visibility: visible;
      transition: all 0.3s ease-out;
      border-bottom: 0.1rem solid $grey3;
    }
  }

}