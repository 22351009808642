.product-tile {
  @include animate;

  &__image-wrapper {
    &>img {
      transform: none;
    }
  }

  &--carousel {
    .product-tile__info {
      padding-right: 0;
      padding-left: 0;
      padding-top: 1.6rem;
    }
  }

  &__card {
    position: relative;
    overflow: hidden;

    .tag-image {
      background: $white;
      font-size: 1rem;
      line-height: 1rem;
      padding: 0.4rem;
      color: $blue;
      height: 1.8rem;
      width: fit-content;

      &--personalization {
        padding: 0.4rem;
        cursor: pointer;
        position: relative;
        
        &:hover {
          width: fit-content;  
          .tag-image__label {
            max-width: 20rem;
            padding: 0.4rem 0.4rem 0.4rem 2.1rem;
          }
        }
      }

      &__label {
        display: block;
        width: max-content;
        height: 1.8rem;
        max-width: 1.8rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.5s ease-in-out;
        background-color: $white;
        padding: 0.4rem 0;
      }

      &-wrapper {
        position: absolute;        
        gap: 0.2rem;
        z-index: 2;
        height: fit-content;
        transition: all 0.3s ease-in-out;
      }
    }

    .collection-in-tile {
      display: none;
    }
  }

  &__link {
    display: block;
    transition: none !important;
    opacity: 1 !important;
    text-decoration: none;

    .wrapper-title {
      width: 100%;
      overflow: hidden;
    }

    .product-brand {
      display: -webkit-flex;
    }

    h2 {
      display: block;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    [data-whatinput='keyboard'] & {
      &:focus {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 $outline-width $outline-color-inverted;
          @include z;
        }
      }
    }
  }

  &__image {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    right: initial;
    transform-origin: top center;
    @include animate;
  }

  &__quick-buy-panel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
  }

  &__info {
    position: relative;
    padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 6);
  }

  &__name {
    @extend %primary-s;
    @include text-shorten;
    display: block;
    margin-bottom: $space-unit;
  }

  &__price {
    @extend %primary-xs;
    display: block;

  }

  &__quick-buy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ($space-unit * 4);
    @include z;

    .button {
      width: auto;
      border: none;
      // border: $space-line solid transparent;
      box-shadow: none;
      padding: 0 $space-unit;
      margin-right: -#{$space-unit};
      margin-bottom: -#{$space-unit};

      &[class] {
        span {
          @extend %primary-xxs;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .button {
    transition: opacity 166ms $easeOutQuart, color 0.5s $easeOutQuart;
  }

  &.is-look {
    .look-toggle {
      position: relative;
      display: block;
      width: 100%;

      &:focus {
        outline: none;

        &::before {
          content: '';
          @include z;
          border: $space-line solid $white;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box;
        }
      }

      &.no-looks {
        cursor: initial;
      }
    }

    .look-number__wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: ($space-unit * 4);
      bottom: 0;
      left: 0;
      width: 100%;
      @include z;
      pointer-events: none;
    }

    .look-number {
      @extend %primary-xxs;
      color: $white;
    }
  }

  &.quickshopactive {
    background-color: $black;
    color: $white;

    .product-tile__image {
      // height: calc(100% - 6.4rem);
      width: auto;
      margin: 0 auto;
      // cursor: ew-resize;
    }

    .button {
      color: $white;
    }

    .selector--color {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $space-line;
        background-color: $white_inactive;
      }
    }
  }

  .tile-swiper-icon {
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    bottom: unset;
    margin-top: unset;

    &.swiper-button-next {
      right: 1.2rem;
      left: unset;
    }

    &.swiper-button-prev {
      left: 1.2rem;
      right: unset;
    }
  }

  .tile-body {
    &-row {
      flex-wrap: nowrap;
      margin-bottom: 0.6rem;

      &-link {
        width: calc(100% - 2rem);
      }
    }

    .tile-last-components {
      flex-wrap: wrap;
      gap: 0.4rem;
      &-promo {
        gap: 0.6rem;
      }
    }

    .product-brand {
      margin-bottom: 0.6rem;
      line-height: 1.6rem;
      font-size: 1.3rem;
    }

    .price,
    .prices .price .price {
      font-size: 1.3rem;
      white-space: nowrap;

      .sales {
        padding: 0 0.2rem;
      }

      span {
        line-height: 1.6rem;
      }
    }
  }

  .icon--wishlist {
    opacity: 1;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0;

    &::after {
      position: static;
      transform: translateY(0);
    }
  }

  .sustainibility-icon {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;

    &::after {
      position: static;
      transform: translateY(0);
    }
  }

  .variation-color-plp {
    display: flex;
    gap: 0.8rem;

    .color-show & {
      display: flex;
    }

    img {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .swatch-cta-quick-add-to-bag {
      color: $grey7;
    }
  }

  .cta-quick-add-to-bag {
    opacity: 0;
  }

  .cta-availability-notice {
    opacity: 0;
  }
}

.js-discount-msg {
  color: $blue_promo;
}

.plp-mobile-color-size-selection {
  .wrapper-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.2rem;
    padding-left: 1.6rem;
    border: 0.1rem solid $gray3;

    .label-color-value {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 8.8rem;
    }
  }

  .button-wrapper-section {
    display: flex;
    width: 100%;
    gap: 0.8rem;
    justify-content: center;

    &>button.button {
      flex-grow: 1;
    }
  }
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}