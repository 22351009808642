.wrapper-eco-banner {
  display: flex;
  padding: 1.6rem;
  gap: 2.2rem;
  align-items: center;
  border: 0.1rem solid #3E7D3933;
  background: #3E7D3908;
  border-radius: 0.4rem;

  p {
    color: green;
  }
}