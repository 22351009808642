@use 'sass:math';

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;

  .mb-#{$multiply} {
    margin-bottom: $space;
  }

  .mt-#{$multiply} {
    margin-top: $space;
  }

  .ml-#{$multiply} {
    margin-left: $space;
  }

  .mr-#{$multiply} {
    margin-right: $space;
  }

  .pb-#{$multiply} {
    padding-bottom: $space;
  }

  .pt-#{$multiply} {
    padding-top: $space;
  }

  .pl-#{$multiply} {
    padding-left: $space;
  }

  .pr-#{$multiply} {
    padding-right: $space;
  }

  .my-#{$multiply} {
    margin-bottom: $space;
    margin-top: $space;
  }

  .mx-#{$multiply} {
    margin-left: $space;
    margin-right: $space;
  }

  .py-#{$multiply} {
    padding-bottom: $space;
    padding-top: $space;
  }

  .px-#{$multiply} {
    padding-left: $space;
    padding-right: $space;
  }

  .gap-#{$multiply} {
    gap: $space;
  }
}

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;

  @include breakpoint(lg, min) {
    .gap-lg-#{$multiply} {
      gap: $space;
    }

    .mb-lg-#{$multiply} {
      margin-bottom: $space;
    }

    .mt-lg-#{$multiply} {
      margin-top: $space;
    }

    .ml-lg-#{$multiply} {
      margin-left: $space;
    }

    .mr-lg-#{$multiply} {
      margin-right: $space;
    }

    .pb-lg-#{$multiply} {
      padding-bottom: $space;
    }

    .pt-lg-#{$multiply} {
      padding-top: $space;
    }

    .pl-lg-#{$multiply} {
      padding-left: $space;
    }

    .pr-lg-#{$multiply} {
      padding-right: $space;
    }

    .my-lg-#{$multiply} {
      margin-bottom: $space;
      margin-top: $space;
    }

    .mx-lg-#{$multiply} {
      margin-left: $space;
      margin-right: $space;
    }

    .py-lg-#{$multiply} {
      padding-bottom: $space;
      padding-top: $space;
    }

    .px-lg-#{$multiply} {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

@each $multiply in $spacing-multiplier {
  $space: $space-unit * $multiply;

  @include breakpoint(lg, max) {
    .gap-sm-#{$multiply} {
      gap: $space;
    }

    .mb-sm-#{$multiply} {
      margin-bottom: $space;
    }

    .mt-sm-#{$multiply} {
      margin-top: $space;
    }

    .ml-sm-#{$multiply} {
      margin-left: $space;
    }

    .mr-sm-#{$multiply} {
      margin-right: $space;
    }

    .pb-sm-#{$multiply} {
      padding-bottom: $space;
    }

    .pt-sm-#{$multiply} {
      padding-top: $space;
    }

    .pl-sm-#{$multiply} {
      padding-left: $space;
    }

    .pr-sm-#{$multiply} {
      padding-right: $space;
    }

    .my-sm-#{$multiply} {
      margin-bottom: $space;
      margin-top: $space;
    }

    .mx-sm-#{$multiply} {
      margin-left: $space;
      margin-right: $space;
    }

    .py-sm-#{$multiply} {
      padding-bottom: $space;
      padding-top: $space;
    }

    .px-sm-#{$multiply} {
      padding-left: $space;
      padding-right: $space;
    }
  }
}

.m-auto {
  margin: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

// class used by js to generate mqObj
.custom-mq {
  $mqObj: '';

  @each $name,
  $value in $grid-breakpoints {
    @if $mqObj !='' {
      $mqObj: $mqObj +'&';
    }

    $mqObj: $mqObj + $name + '=' + $value;
  }

  font-family: $mqObj;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-column {
  display: flex;
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

@each $name,
$br in $grid-breakpoints {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }

    .d-#{$name}-hide {
      visibility: hidden !important;
    }

    .d-#{$name}-block {
      display: block !important;
    }

    .d-#{$name}-flex {
      display: flex !important;
    }
  }
}

@include breakpoint(md, max) {
  .hide-mobile {
    display: none !important;
  }
}

.hidden {
  // note that this is hidden to screen reader
  visibility: hidden;
}

.hide {
  display: none !important;
}

.link {
  text-decoration: underline;
}

.block {
  display: block;
}

.full-width {
  width: 100%;
}

img[data-sizes='auto'] {
  display: block;
  width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.link-button {
  text-decoration: underline;
}

.info-box {
  padding: 2.4rem;
  border-radius: 0.8rem;
  background-color: $grey3;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;

    i {
      margin-right: 0.8rem;
    }
  }

  p {
    @extend %primary-s;
    color: $grey8;
  }
}

.bb-1 {
  border-bottom: 0.1rem solid $gray3;
}

.bb-1-gray8 {
  border-bottom: 0.1rem solid $gray8;
}

.bt-1 {
  border-top: 0.1rem solid $gray3;
}

.scrollbar-custom {
  overflow-y: auto;

  &:hover {
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    visibility: hidden;
    transition: all 0.3s ease-out;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey1;
    border-radius: 0.4rem;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey5;
    border-radius: 0.4rem;
    transition: all 0.3s ease-out;
    visibility: hidden;

    &:hover {
      background-color: $grey6;
    }
  }
}

.scrollbar-hidden {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}

#root {
  .button {
    max-width: 30rem;
  }
}

.no-wrap{
  white-space: nowrap;
}