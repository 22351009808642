body {
  &#product-show {
    .row-pickup-point-container {
      #pickupPointMap {
        .gm-style-iw {
          max-width: unset !important;
        }
      }
    }
  }
}

.row-pickup-point-container {
  height: 20rem;
  width: 100%;

  #pickupPointMap {
    display: block;
    height: 100%;

    .gm-style-iw {
      padding: 2.4rem !important;
      top: -1.3rem;

      .gm-style-iw-d {
        padding: 0 !important;
        max-height: unset !important;
        overflow: auto !important;
      }

      .rt-iw {
        text-align: center;

        .rt-iw__heading {
          padding-bottom: 1.6rem;
          margin-bottom: 1.6rem;
          border-bottom: 0.1rem solid $grey3;

          .rt-iw__name {
            font-size: 1.6rem;
            margin-bottom: 0.8rem;
          }

          .rt-iw__address {
            font-size: 1.3rem;
            line-height: 1.6rem;
          }
        }

        .rt-iw__contacts {
          margin-top: 1.6rem;
          display: flex;
          justify-content: center;
          gap: 1.1rem;
          align-items: center;
          padding-top: 1.6rem;
          border-top: 0.1rem solid $grey3;

          a {
            text-decoration: unset;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $blue;
            font-weight: 500;
          }
        }
      }

      .gm-ui-hover-effect {
        display: none !important;
      }
    }

    .gm-style-iw-tc {
      top: -1.3rem;
    }
  }
}