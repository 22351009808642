header .menu-actions .search-menu-wrapper .search-container {
  display: flex;
  align-items: center;

  p.label {
    color: #64717B;
    width: auto;
    transition: 0.4s all ease-out;
  }

  .divider {
    height: 1.2rem;
    width: 0.1rem;
    margin: 0 1.6rem;
    background: $gray5;
  }

  .wrapper-search {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    @include z;
    opacity: 0;
    transition: opacity 0.2s ease-out;

    &.active {
      opacity: 1;
    }
  }

  .search-field {
    position: relative;
    display: flex;
    align-items: center;
    height: 6.4rem;
    border-bottom: 0.1rem solid $grey3;

    &::after {
      content: '';
      display: block;
      @include fixedSprite('search');
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 3.4rem;
      transform: translateY(-50%);
      @include z;
    }

    input {
      padding: 0 2.4rem 0 6.4rem;
      font-size: 1.6rem;

      line-height: 2.4rem;
      appearance: initial;
      border: initial;
      background-color: initial;
      flex-grow: 1;
      height: 100%;
    }

    button {
      width: 5.6rem;
      background-size: 1.6rem;
      border-left: 0.1rem solid $grey3;
    }
  }

  .results-container {
    display: grid;
    grid-template-columns: 25% 70%;
    padding: 4rem 2.4rem;
    gap: 5%;

    .submenu-heading {
      color: $gray6;
      margin-bottom: 1.6rem
    }

    .view-all-suggestion-item {
      border: 0.1rem solid lightgray;
      text-align: center;
      border-radius: 0.4rem;
      padding: 1rem 2.4rem;
      width: 65%;
      margin-top: 1.2rem;
      color: $grey7;
    }
  }

  .suggestion-item {
    color: $grey7;

    b {
      color: $blue;
    }
  }

  .minitile-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.2rem;
  }

  .search-minitile {
    display: flex;
    gap: 0.8rem;
    outline-offset: 0.1rem;
    flex-direction: column;

    .img-wrapper {
      display: block;
      width: 100%;
      height: auto;
      background: $grey3;
    }

    img {
      width: inherit;
      -webkit-filter: blur(.5rem);
      filter: blur(.5rem);
      transition: filter 400ms, -webkit-filter 400ms;

      &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
      }
    }

    .minitile-info {
      flex: 1;
      padding: 0;
      line-height: 2.1rem;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 0.2rem;
      color: $blue;

      h3 span {
        display: block;
        color: $grey8;
        margin-top: $space-unit;
      }
    }
  }

  .submenu-recent-popular {
    display: flex;

    .submenu-recent,
    .submenu-popular {
      width: 50%;
    }
  }

  .submenu-group ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &.collapsed {
    .search-iconflex-center {
      &::before {
        margin-right: 0;
      }

      .label {
        display: none;
      }
    }
  }
}

header:has(.nav-wrapper.nav-white) .menu-actions .search-menu-wrapper .search-container {
  p.label {
    color: $white;
  }
}

.error-500-wrapper {
  height: calc(100vh - 7.2rem);
  display: flex;
  justify-content: center;
  flex-direction: column;

  .title-icon-wrapper {
    position: relative;

    .title {
      font-size: 24rem;
      line-height: 24rem;
      margin: 5.8rem 0;
      text-align: center;
    }

    .error-500-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  p {
    text-align: center;

    &.text-wrapper {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    &.need-help-label {
      font-size: 1.4rem;
      color: $grey8;
      line-height: 2rem;
    }
  }

  .social-wrapper {
    a {
      border-bottom: 0.1rem solid $blue;
    }
  }
}

body {
  .container-404 {
    padding: 10.8rem 0;

    .title {
      font-size: 16rem;
      line-height: 16rem;
      color: $blue;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    .text-wrapper {
      font-size: 2rem;
      line-height: 2.6rem;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    .suggested-categories {
      margin-top: 12rem;
      padding: 0 14rem;
      gap: 1.2rem;

      .suggested-category {
        width: auto;
        flex: 1 1 0;
        min-height: 0;
        min-width: 0;
        display: flex;
        flex-direction: column;
        text-align: center;

        h2,
        a {
          flex: 0 0 auto;
          width: 100%;
          margin-bottom: 1.2rem;

          &:last-child {
            margin-bottom: 0;
          }

          &.suggested-category-title {
            margin-bottom: 2.4rem;
          }
        }
      }
    }

    .product-carousel__carousel-container {
      margin-top: 12rem;

      .product-carousel__title {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
}