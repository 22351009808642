@each $font-type, $font-data in $font-desktop {
  %primary-#{$font-type},
  .primary-#{$font-type} {
    font-size: nth($font-data, 2);
    line-height: nth($font-data, 3);
    @if str-index($font-type, '-u') {
      text-transform: uppercase;
    }

  }
}