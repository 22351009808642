.footer {
  .accordion__header {
    padding: 1.6rem;
  }
}

body {
  &#product-show {
    #maincontent {
      .accordion__panel {
        .eco-value-wrapper {
          align-items: center;
          gap: 2.4rem;
          background: rgba(62, 125, 57, 0.03);
          border: 0.1rem solid rgba(62, 125, 57, 0.2);
          border-radius: 0.4rem;
          padding: 1.6rem;
          margin-bottom: 0.8rem;

          &.eco-recycle {
            margin-bottom: 0;
            border-radius: 0.4rem 0.4rem 0 0;
          }

          .row {
            &.eco-circolarita-wrapper {
              gap: 2.4rem;
              align-items: center;

              .ecoCircolaritaInfo {
                flex: 0;
              }
            }
          }

          &.container-ecoCircolarita {
            .icon--leaf-wrapper {
              flex: 1 0 0;
            }
          }
        }
      }
    }
  }
}

body {
  &#accountapi-loyaltylanding {
    #maincontent {
      .accordion__header {
        padding: 1.6rem 1.2rem;

        &[aria-expanded='false'] {
          &::after {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

.personalization-modal {

  .accordion {
    &__header {
      &__title {
        &_selected {
          &_personalization {
            max-width: 13rem;
          }
          &_position {
            max-width: 12rem
          }
        }
      }
    }
  }
}