.register_form {
  .minibag__buttons__wrapper {
    flex-direction: column;
  }
}

.registration-legal-box .radio-wrapper {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mini-form .row {
  gap: unset;

  .col-8 {
    max-width: 100%;
  }

  &.phone-form {
    gap: 0.8rem;
  }
}