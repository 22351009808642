.order-tile {
  margin-bottom: 1.6rem;

  .order-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    padding-bottom: 1.6rem;
    line-height: 2rem;
    border-bottom: 0.1rem solid $grey3;

    .primary-l {
      font-size: 1.4rem;
      display: flex;
      gap: 0.4rem;
    }

    &__store {
      text-transform: capitalize;
    }

    .Created,
    .InProgress {
      color: #25428A;
    }

    .Canceled {
      color: #C9240D;
    }

    .Shipped,
    .Returned,
    .Ordered {
      color: #3E7D39;
    }
  }

  .order-footer {
    align-items: center;
    margin-bottom: 1.6rem;

    .view-order-button {

      a {
        line-height: 1.6rem;
      }
    }

    &__totals {

      .InProgress,
      .Ordered,
      .Shipped {
        color: $dark_blue;
      }

      .Canceled {
        color: $red;
      }

      .Returned {
        color: $green;
      }
    }

    &__tracking {
      align-content: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  .view-order-button {
    &.for-mobile {
      display: none;
    }
  }

  .order-items {
    display: flex;
    gap: 0.7rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.order-item.returned>*,
.order-item.returned>*>* {
  color: rgb(0 0 0 / 30%);
}

.order-item.returned .order-item__image {
  opacity: 0.3;
}

.order-item.returned .order-item__content .order-item__details .returnLabel {
  color: $red;
}

.order-items {
  display: flex;
  gap: 3.2rem;
  flex-direction: column;

  .order-item {
    width: 8.8rem;
    height: 12.2rem;
    margin-bottom: 1.6rem;

    .order-item__image {
      img {
        width: 8.8rem;
        height: 12.2rem;
      }
    }

    &.more-products {
      background: $grey1;
      border-radius: 0.4rem;

      .icon--more-products-card {
        display: block;
      }

      p {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 1.6rem;
      }
    }

    &__image {
      height: 100%;
      flex-basis: 11rem;


      img {
        width: 100%;
      }
    }

    &__price {
      margin-top: 0.2rem;
      margin-bottom: 0.8rem;
    }

    &__details {
      display: flex;
      gap: 0.8rem;
      flex-direction: column;
    }

    .order-item__content {
      padding-left: 1.6rem;
      flex: 1;
    }

    .form-select {
      margin-bottom: 1.6rem;
    }
  }

  .checkbox {
    margin-right: 0.8rem;
  }
}

.account-dashboard {

  .orders-listing,
  .wishlist {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .component_title {
        margin-bottom: 0;
      }
    }
  }
}

.order-items.disabled {
  opacity: 0.3;
}

body#order-return {
  .back-to-account-wrapper {
    display: none;
  }
}

.order-detail-page-return {
  max-width: 69rem;
  margin: auto;

  .infoBox {
    &__container {
      padding-bottom: 2.4rem;
    }
  }

  .order-heading {
    .back-to-details-wrapper {
      gap: 0.8;
      align-items: center;
    }
  }

  .order-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2.4rem;

    p {
      display: flex;
      flex-direction: column;
    }

    .label {
      font-size: 1.2rem;
      color: $grey8;
      margin-bottom: 0.6rem;
    }
  }

  .order-items {
    .order-item-wrapper {
      &:not(:last-child) {
        margin-bottom: 1.8rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .order-item {
        display: flex;
        flex-direction: row;
        width: unset;
        height: unset;

        &__image {
          flex-basis: 8.6rem;
        }
      }
    }
  }

  .return-item-box {
    .order-items {
      .order-history-card-details {
        .order-image-wrapper {
          img {
            width: 8.6rem;
          }
        }
      }
    }
  }
}