.footer {

  .col,
  [class^='col-'] {
    padding: 0;
  }

  .container {
    padding: 4.8rem 0 2.4rem;
    margin: 0;
  }

  .col--footer-nl {
    padding: 0 1.6rem;

    .ovs-icon,
    .ovs-icon+.title-4 {
      text-align: center;
    }
  }

  .col--footer-menu {
    order: 2;

    .accordion__panel {
      padding: 0 1.6rem;
    }
  }

  .footer-policies {
    text-align: center;
    justify-content: center;
  }

  .socials-languages {
    order: 3;
    padding: 2.4rem 1.6rem 0;
    flex-direction: column;

    .social-wrapper {
      gap: 4.8rem;

      a {
        height: 1.6rem;
      }
    }
  }

  .footer-menu__section {
    margin-top: 0;
    padding-bottom: 0;
    display: grid;
    grid-gap: 0 2rem;
    grid-template-columns: auto auto;

    & a {
      line-height: 2rem;
    }
  }

  .footer__nl {
    padding-bottom: 0;
    margin: auto;
    max-width: 53rem;
  }

  .footer-menu--col {

    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $black;
      @include border('top');
      // padding-top: 0.5rem; // TYPOGRAPHY TRICK
      min-height: 4.2rem;
      width: 100%;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  .languageselector {
    margin: auto;
  }

  .social-legals {
    align-items: baseline;

    .copyright {
      text-align: center;
    }
  }

  .footer-row {
    flex-direction: column;
  }

  .footer-bottom {
    display: block;
    border: 0;
    padding: 2.4rem 2.4rem 4.8rem;
    margin: 0;
  }
}

.newsletter-container {
  .invalid-feedback.globalerrors {
    left: 0.4rem;
    bottom: 1.6rem;
  }
}

#newsletter-modal {
  .modal-container {
    position: absolute;
    width: calc(100vw - 3.2rem);
    left: 0;
    right: 0;
    bottom: 2.4rem;

    &.modal {
      width: 48.4rem;
    }

    &__img-container {
      display: none;
    }

    &__text-holder {
      width: 100%;
      padding: 2.2rem ($space-unit * 4);
    }
  }
}

.language-box, .multicountry-box {
  left: 0;
  right: 0;
  max-width: 100vw;
  width: 100vw;
  max-height: 80vh;
  top: unset;
  bottom: 0;

  .language-header, .multicountry-header {
    padding: 2.2rem 1.6rem;
  }

  .language-body, .multicountry-body {
    margin: 2.2rem 1.6rem;
    padding: 0;
  }

  .language-footer, .multicountry-footer {
    padding: 2.2rem 1.6rem;
    margin: 0;
  }
}

.multicountryPopup {
  &-box {
    right: 0;
    margin: auto;
    left: 0;
  }
}