[class*='-info-discount']:not(:has([class*='-info-discount'])) {

  .approaching-discount {
  }

  .applied-discount {
    color: $green;
  }

  .content-slot-promo {
  }
}