/*icona minimized*/
.embeddedServiceSidebarMinimizedFabDefaultUI button,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:hover,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:focus,
.waitingCancelChat {
  background: $white;
  background-color: #12253c;
}

/*fumetti operatori*/
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent,
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: #ffff;
}

/*background*/
.embeddedServiceLiveAgentStateChat .messageArea.smoothScroll,
.embeddedServiceSidebarDialogState .dialogState,
.embeddedServiceSidebarFeature {
  background: #f1f2f4;
}

.embeddedServiceLiveAgentStateChatItem.chatMessage {
  background: unset;
}

/*fumetto agnte*/
.embedded-service .fab {
  background: #FFFFFF !important;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .dismiss-button {
  border-style: none;
  background-color: #12253c;
  color: $white;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .message {
  background-color: #ffff;
  border-style: none;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .fab {
  border-color: unset;
}

/*cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton:not(:disabled):hover,
.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus {
  background: #12253c;
}


/*testo cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton.uiButton--inverse .label,
.embeddedServiceSidebarButton .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:focus .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:hover .label {
  color: $white;
}

.embeddedServiceIcon svg {
  fill: $white;
  width: 2.2rem !important;
}

.embeddedServiceSidebarButton:focus {
  text-decoration: none;
  text-decoration-color: none;
}


.embedded-service .channelmenu_ctas>li:first-child:not(.chatDisabled)+li,
.embedded-service .channelmenu_ctas>li:not(.chatDisabled):first-child>li,
.embedded-service .channelmenu_ctas>li[class~="chatDisabled"] {
  display: none !important;
}

.embedded-service .channelmenu {
  bottom: 8.5rem !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  color: #12253c !important;
}


.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor1 {
  background-image: url('https://ovsspaclienti.file.force.com/servlet/servlet.ImageServer?id=0155p000008KzyY&amp;oid=00D0N000000jojk&amp;lastMod=1684319811000');

}

.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar.iconAvatar {
  color: transparent;
}

.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent a {
  color: #000000;
}

.queuePositionMessage p::first-line {
  font-weight: bold;
}

.sidebarHeader.minimizedContainer.alert {
  padding: 0;
}

.embedded-service .fab div[class="icon_fab_container"]>img.esw-custom_icon {
  width: auto;
}

.default.embeddedServiceSidebarMinimizedFabDefaultUI {
  width: unset;
}

.embedded-service .fab div[class=icon_fab_container]>img.esw-custom_icon {
  width: auto !important;
}

.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton:not(:disabled):hover,
.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus {
  background: #12253c !important;
}

.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton.uiButton--inverse .label,
.embeddedServiceSidebarButton .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:focus .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:hover .label {
  color: #ffffff !important;
}

.embeddedServiceLiveAgentStateChatItem.chatMessage {
  background-color: unset !important;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent,
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: #ffffff !important;
}


.embedded-service .fab:focus:before {
  background-color: #fff !important;
}

.embedded-service .fab div[class="icon_fab_container"],
.embedded-service .fab div[class="icon_fab_container"]>svg.esw-default_icon {
  max-width: 2.8rem !important;
}

.embedded-service .fab:focus {
  box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(45, 45, 45, 0.5) !important;
}

svg#esw-icon-close {
  position: relative;
  top: 0.2rem;
}

#esw-channelmenu_header>span {
  font-size: 1.8rem;
  letter-spacing: 0.04em;
  margin-bottom: 0.8rem !important;
  display: inline-block;
  font-weight: 700 !important;
}

.embedded-service #esw-fab.showAnimation,
.embedded-service #esw-channelmenu.showAnimation {
  z-index: 4;
}

.embedded-service #esw-fab.showAnimation {
  bottom: calc(1.56rem + var(--scrolling-banner) + env(safe-area-inset-bottom, 0))
}

.embedded-service #esw-channelmenu.showAnimation {
  bottom: calc(6.25rem + var(--scrolling-banner) + env(safe-area-inset-bottom, 0));
}
.embedded-service>#esw-modaloverlay.isMaximized+div#esw-container>div.channelmenu.showAnimation,
.embedded-service>#esw-modaloverlay.isMaximized+div#esw-container>button.fab.showAnimation,
#embedded-service.embedded-service>#esw-modaloverlay.isMaximized {
  z-index: 4;
}

#embeddedMessagingConversationButton.embeddedMessagingConversationButton,
.embeddedMessagingFrame#embeddedMessagingFrame {
  bottom: calc(1.6rem + var(--scrolling-banner));
}