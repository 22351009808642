[data-whatinput='mouse'] {
  .a11y-action-bar {
    display: none;
  }
}

.a11y-action-bar {
  background-color: $black;
  min-height: 2.4rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  @extend .visible-on-focus;
  @extend %primary-xxs-u;

  a,
  a:link,
  a:visited {
    color: $white;
    @include link-focus-underline;
    * {
      color: $white !important;
    }
  }

  &:focus {
    outline: none;
  }
}

.wrapper-submenu {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
  }
}

.minibag {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -6;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}

.account-dropdown {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -8;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}

.genius-designers {
  .a11y-action-bar {
    padding: $space-unit * 2 $space-unit * 8;

    flex-direction: column;
    align-items: flex-start;

    .genius-designer__controls {
      position: relative;
      align-self: center;
    }

    ul,
    ol {
      @include reset-list;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        padding: $space-unit * 2 0;

        a {
          margin-right: 0;
          p {
            display: inline;
          }
        }

        &:not(:last-child) {
          &::after {
            content: '|';
            color: $white;
            padding: 0 $space-unit * 2;
            display: inline-block;
          }
        }
      }
    }
  }
}
