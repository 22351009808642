.modal {
  position: absolute;
  background: $white;
  height: 100%;
  width: 100%;
  overflow: auto;

  &.modal--small {
    max-width: 50rem;
  }

  &.sizevariation-modal {
    height: auto;
  }

  &#filters-modal {
    opacity: 0;
    visibility: hidden;

    &.is-open {
      @include z('modal');
      opacity: 1;
      visibility: visible;
    }
  }

  // overflow-x: hidden;
  &.modal--no-padding {
    padding: 0;
  }

  &__body {
    max-height: 70vh;
    height: auto;

    &.right-modal {
      max-height: unset;
      overflow: auto;
      padding: 2.4rem;
    }

    &.with-footer {
      height: calc(100vh - 6.4rem - 9.6rem);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    display: flex;
    padding: 2.2rem 1.6rem;
    border-bottom: 0.1rem solid $grey3;
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.6rem;
  }

  &.modal--centered {
    text-align: center;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 68.6rem;
    min-width: 40%;
  }

  &.modal--minheight {
    min-height: 90vh;
    display: flex;
    align-items: stretch;

    .modal__body {
      width: 100%;
    }
  }

  .withimage {
    &__body {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      height: 100%;
      min-height: 30rem;
    }

    &__right {
      overflow: hidden;
      margin-top: 2.2rem;
      padding-left: $space-unit * 4;
      padding-right: $space-unit * 4;
      display: flex;
      flex-direction: column;
    }

    &__left {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $black;
    }
  }
}

.modal.profile-modal,
.modal.address-modal {
  max-width: 50rem;
  width: 100%;
  min-width: unset;
  padding: 0;
  border-radius: 0.4rem;

  .modal__header {
    padding: 0;
    border-bottom: 0.1rem solid $grey3;

    .dialog__title {
      padding: 1.8rem 2.4rem;
    }
  }

  .modal__body {
    .edit-profile-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding: 2.4rem;
    }

    .button-wrapper {
      padding: 0 2.4rem 2.4rem;
    }

    .globalerrors.invalid-feedback:not(.otp-error) {
      margin-left: 2.4rem;
      margin-bottom: 0;
      margin-top: 2.4rem;
    }
  }
}

.modal__divisor {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border: none;
  @include border('top');

  &.noabs {
    position: relative;
  }
}

.dialog__description {
  line-height: 1.6rem;
  margin-top: -0.6rem;
  margin-bottom: ($space-unit * 6);
}

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2.4rem;
  z-index: 3;

  &:focus {
    outline: transparent;
  }
}

.modal__intro {
  margin: ($space-unit * 6) 0;

  .status {
    @extend %primary-s;
    display: block;
    margin-bottom: ($space-unit * 2);
  }
}

.overlay-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($color: $black, $alpha: 0.3);
  @include z(modal);
}

.modal.login-modal {
  width: 40rem;
  right: 0;
  transform: translate(0);
  top: 0;
  left: auto;
  height: 100vh;

  .modal {
    &__header {
      padding: 2.2rem 2.4rem;

    }

    &__body {
      padding: 2.4rem;
      max-height: calc(100% - 6.5rem);
      overflow: auto;

      .login {
        &-modal {
          &_guest {
            border-top: 0.1rem solid $grey3;
          }
          &__form_disabled {
            border: 0.1rem solid $grey3;
            border-radius: 0.4rem;

            input {
              border: 0;
            }
          }
        }

        &__notYou_link {
          color: $grey8;
          font-size: 1.4rem;
          line-height: 1.8rem;

          &::before {
            border-color: $grey8;
          }
        }
      }

    }
  }
}

.country-modal,
.language-wrapper {

  ul,
  ol {
    @include reset-list;

    li {
      a {
        @extend %primary-s;
        @include del-link;
        padding: ($space-unit) 0;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;

        .countryname {
          margin-right: 0.6rem;
          display: inline-block;
        }

        .icon--bag {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  @include animate(0.4, 'opacity');

  &--after-open {
    opacity: 1;
    z-index: 11000 !important;
    &:has(.address-modal) {
      z-index: 1000 !important;
    }
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  transition: all 0.3s ease-out;
  transform: translate(100%, 0);

  &--after-open {
    transform: translate(0);
  }

  &--before-close {
    transform: translate(100%, 0);
  }

  &.right-modal-attribute {
    position: fixed;
    right: 0;
    left: unset;
    transform: unset;
    top: 0;
    height: 100%;
    width: auto;

    &.loyalty-subscribe-modal {
      width: 40rem;
    }
  }

  &.centerModal {
    transform: translate(0);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: fit-content;
    max-width: 73.8rem;
    border-radius: 0.8rem;

    .modal__body {
      max-height: 65rem;
      overflow: hidden auto;
      margin: 2.4rem;
    }
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0 !important;
}

#button-filter-modal-horizontal {
  color: $grey8;
  padding: 0 2.4rem;
}

#button-filter-modal-horizontal.selected {
  color: $blue;

  & span::after {
    @include fixedSprite('filters');
  }
}

.modal-promo {
  .promo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(34, 34, 34, 0.5);
    z-index: 50;
    transition: all 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
    visibility: visible;
    opacity: 1;

    &.closed {
      opacity: 0;
      visibility: hidden;

      &+.promos-wrapper {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .promos-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    width: 41.4rem;
    height: 100%;
    padding: 0;
    opacity: 1;
    visibility: visible;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.1, 0.3, 0.4, 1);
    z-index: 60;
    background-color: $white;

    .title {
      padding: 2rem 2.4rem;
      border-bottom: 0.1rem solid $grey3;

      .promo-close {
        position: absolute;
        right: 2.4rem;
        top: 2.4rem;
      }
    }

    .slot-wrapper {
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      overflow: auto;

      .promo-container {
        background-color: $promo_pink;
        padding: 2.4rem;
        color: $light_orange;
        border-radius: 0.4rem;

        .copy-button {
          text-align: center;
          border: 0.2rem solid $white;
          padding: 1.2rem;
        }
      }
    }
  }

  .modal-isml {
    display: block;

    .modal-overlay {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 4rem;
      top: unset;
      left: unset;
      background: transparent;
      z-index: 1;

      .modal-container {
        max-width: 35rem;
        width: 35rem;
        border: 0.1rem solid $grey6;

        .modal-close {
          display: none;
        }

        .modal-content {
          padding: 1.6rem 2rem;
          cursor: pointer;

          .icon--plus1 .icon--right::after {
            position: absolute;
            right: 2rem;
            top: 1.8rem;
          }

          .icon--gift-b .icon--left::before {
            vertical-align: text-top;
          }
        }
      }
    }
  }
}

.modal-isml,
.tingle-modal {
  display: none;

  .modal-close {
    display: block;
    background-color: unset;
    cursor: pointer;

    &::after {
      width: 0;
      height: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin: 2.4rem;
      content: '';
      @include fixedSprite('close');
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: auto;
    z-index: 9999;
  }

  .modal-body {
    padding: 2.4rem;
    &.add-coupon-confirmation-body{
      padding-bottom: 0;
    }
    &--error-message {
      padding: 0.8rem 2.4rem 0;
      .error-message-coupon-modal {
        color: $status_error;
      }        
    }
  }

  .modal-container {
    position: relative;
    background-color: $white;
    padding: 0;
    max-width: 50rem;
    width: 100%;
    max-height: 100vh;
    overflow-y: hidden;
    box-sizing: border-box;
    will-change: auto;

    &.tiny {
      max-width: 40rem;
    }
  }

  .modal-header {
    padding: 2.2rem 2.4rem;
    border-bottom: 0.1rem solid $gray3;
  }

  .modal-footer {
    padding: 2.4rem;
    gap: 1.6rem;
    display: flex;
    flex-direction: column;
    bottom: 0;
    position: absolute;
    width: 100%;

    button {
      width: 100%;
    }
  }

  .modal-footer-buttons {
    button {
      width: 100%;
    }
  }

  .modal-header {
    height: 6.4rem;
  }

  .modal-content {
    padding-top: 0;
    height: calc(100vh - 6.4rem - 9.6rem);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.modal-full-width {
    .modal-container {
      max-width: 100vw;
    }
  }

  &.modal-left,
  &.modal-right {
    .modal-container {
      border-radius: 0;
    }

    .modal-header {
      @extend %primary-l;
    }
  }

  &.modal-left {
    .modal-overlay {
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &.modal-right {
    .modal-overlay {
      justify-content: flex-end;
      align-items: stretch;
    }
  }

  &.modal-bottom {
    bottom: 0;

    .modal-overlay {
      background: transparent;
      top: inherit;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .modal-container {
      border-radius: 0;
      box-shadow: 0 0 1.1rem rgba(0, 0, 0, 0.25);
    }
  }

  @media (max-width: get($grid-breakpoints, sm)-1) {
    &.modal-md-bottom {
      bottom: 0;

      .modal-overlay {
        background: transparent;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .modal-container {
        border-radius: 0;
        box-shadow: 0 0 1.1rem rgba(0, 0, 0, 0.25);
      }
    }
  }

  &.map-modal {
    .modal-container {
      width: 100%;
      height: 100%;
      max-width: 71rem;
      max-height: 58rem;
    }
  }

  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] {
    .modal-overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .modal-container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] {
    .modal-overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .modal-container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .accordion__panel {
    position: relative;
  }

  .dropdown .values {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}

.ReactModal__Content.centerModal.shopTheLookModal {
  background-color: $gray1;
  opacity: 0;
  overflow: visible;

  &:has(.swiper-slide-active) {
    opacity: 1;
  }

  .modal__header {
    padding: 2.4rem;
    padding-bottom: 1.2rem;
    border: 0;
  }

  .modal__body {
    margin: 0 2.4rem;
    overflow: visible;
  }

  .swiper-shop-the-look-button {

    &-prev,
    &-next {
      position: absolute;
      top: 13.6rem;
      padding: 1rem;
      z-index: 2;
      background: #ffffff40;
      border-radius: 0.4rem;
    }

    &-prev {
      left: 0.8rem;
    }

    &-next {
      right: 0.8rem;
    }
  }
}

#shopTheLookModal {
  position: relative;

  .shopTheLookModal-carousel {
    overflow-x: clip;

    &-tiles {
      width: calc((100% - 2.4rem) / 3);
    }
  }

  .product-carousel__headline {
    &-wrapper {
      margin: 0.8rem;
      margin-bottom: 1.2rem;
    }

    &-title {
      margin-bottom: 0.2rem;

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &-brand {
      margin-bottom: 0.2rem;
      min-height: 1.8rem;
    }
  }

  .selector-color-expanded .selector__menu,
  .selector-size-expanded {

    .size-selector-label-wrapper,
    .color-selector-label-wrapper {
      &[aria-expanded="true"] {
        border-radius: 0.4rem 0.4rem 0 0;

        .size-default-wrapper,
        .color-default-wrapper {
          border-bottom: 0;
        }

        ul {
          border: 0.1rem solid $blue;
          max-height: 14rem;
          border-width: 0 0.1rem 0.1rem;
          border-top: 0.1rem solid #e7e7e7;
        }
      }

      ul {
        border: 0.1rem solid transparent;
        position: absolute;
        top: 4.7rem;
        left: 0;
        border-radius: 0 0 0.4rem 0.4rem;
        z-index: 1;

        li {
          background-color: $white;

          &:hover {
            border-radius: 0;
            background-color: $gray1;
          }
        }
      }
    }

    .color-selector-label-wrapper {
      &[aria-expanded="true"] {
        ul {
          z-index: 2;
        }
      }
    }
  }
}