body.tingle-enabled {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0 !important;
}

html.html-tingle-enabled {
  overflow: hidden;
}

.modal {
  .fade {
    display: none;
  }
}

.tingle-modal-box.right-modal-attribute,
.tingle-modal.right-modal-attribute .tingle-modal-box {
  right: 0;
  position: absolute;
  height: 100vh;
}

.tingle-modal {
  z-index: 10500;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.3);
  cursor: default;
  transition: all 0.3s ease-out;

  &.shipping-address-block {
    z-index: 1000;
  }

  &.tingle-modal--visible {
    visibility: visible;
    opacity: 1;

    .tingle-modal-box__footer {
      bottom: 0;
    }

    .tingle-modal-box {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__close,
  &__closeLabel {
    display: none;
  }

  &--noClose {
    &.tingle-modal__close {
      display: none;
    }
  }
}

.modal-go-back .tingle-modal-box {
  padding: 2.4rem;
  border-radius: 0.8rem;

  button.button--secondary.icon--close::after {
    content: none;
  }
}

.quickview-modal .tingle-modal-box {
  right: 0;
  position: absolute;
  height: 100%;
  max-height: 100%;
  background-color: $white;
  width: fit-content;
  max-width: unset;

  .tingle-modal-box__content {
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    .detail-panel {
      display: flex;
      align-items: flex-start;

      .quantity {
        display: flex;
        flex-wrap: nowrap;
        border: 0.1rem solid $grey3;
        border-radius: 0.5rem;
        width: 11rem;
        height: 4.8rem;
        margin: 1.4rem 0 1.7rem;
        align-items: center;
        justify-content: space-around;

        .quantity-icon {
          display: flex;

          button {
            margin: auto;
          }

          .inactive {
            opacity: 0.3;
            pointer-events: none;
          }
        }

        .quantity-number {
          line-height: 2.2rem;
          text-align: center;
        }
      }

      .size {
        margin: 1.4rem 0 1.7rem;
      }
    }

    .product-quickview {
      width: 42rem;
      position: sticky;
      top: 0;
      height: 100vh;

      .quickview-header {
        .quickview-close {
          position: absolute;
          right: 0;
          top: 0;
          margin: 2.4rem 2.4rem;
          height: 2.1rem;
        }
      }

      .quickview-content {
        padding: 4rem 2.4rem 1.8rem;

        [class^='title'] {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        select {
          padding: 1.5rem;
          border-radius: 0.4rem;
          border: 0.2rem solid $grey_line;
          cursor: pointer;
          padding-right: 0.4rem;
          transition: all 0.3s ease-out;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          @include fixedSprite('chevron-down-gray');
          width: 100%;
          height: 100%;
          background-position: 96% 50%;
        }

        .attribute {
          .variation-color-quickview {
            display: flex;
            gap: 0.8rem;

            .color-show & {
              display: flex;
            }

            .color-attribute {
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                border: 0.1rem solid $disabled_txt_grey;
              }

              &.selected {
                border: 0.1rem solid $blue;
              }
            }

            .swatch-circle {
              height: 1.4rem;
              width: 1.4rem;
              border-radius: 50%;
            }
          }

          .color {
            width: 100%;
          }
        }

        &__pricePromo {
          gap: 0.8rem 1.2rem;
          
          .unit-price {
            .price {
              .price {
                gap: 1.2rem;

                &__sale-wrapper {
                  gap: 1.2rem;                  
                }

                .value,
                .js-discount-msg {
                  font-size: 2rem;
                  line-height: 1.6rem;
                }

              }
            }
          }          
        }


      }

      .modal-footer {
        position: absolute;
        width: 100%;
        bottom: 0;

        .quick-edit-buttons {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }

    .quickview-image {
      width: 42rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 80vw;
  max-width: 40rem;
  background: $white;
  cursor: auto;
  overflow-y: auto;
  transform: translateX(100%);
  transition: all 0.3s ease-out;

  &__content {
    .modal-body {
      margin: auto;

      >*:not(:first-child, :last-child) {
        margin: 0.8rem 0;
      }
    }

    button {
      margin-bottom: 0;

      &.js-apply-coupon {
        margin-bottom: 4.2rem;
      }
    }

    .bottom-buffer {
      margin-bottom: 10vh;
    }
  }

  .modal__closeIcon {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    font-size: 2em;
    cursor: pointer;
  }

  &__footer {
    padding: 1rem 2rem;
    width: auto;
    cursor: auto;
    text-align: right;

    &:after {
      display: table;
      clear: both;
      content: '';
    }

    &--sticky {
      position: fixed;
      bottom: -20rem; // TODO: find a better way
      z-index: 100010;
      opacity: 1;
      transition: all 0.3s ease-out;
    }

    button {
      margin-bottom: 0;
    }
  }

  &.resize-modal-loyalty {
    width: 60vw;
  }
}