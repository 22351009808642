.tooltip-persistent {
  position: relative;

  &--info {
    display: flex;
    flex-direction: column;
  }
  
  &-modal {
    position: absolute;
    bottom: calc(100% + 1.2rem);
    background: $white;
    padding: 1.2rem 2.4rem;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 0 0.4rem 0.4rem 0 $black_shadow;
    border-radius: 0.4rem;
    text-align: left;
    width: 31.8rem;

    &:after{
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 1.1rem);
      z-index: 1000;
      pointer-events: none;
      user-select: none;
      background: transparent;
      border: 0.6rem solid transparent;
      border-top-color: $white;
    }

    &--checkbox {
      transform: translateX(0);
      left: 0;

      &::after {
        left: 1.6rem;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;

      &_close {
        max-width: 1.6rem;
        max-height: 1.6rem;
      }
    }
  }
}