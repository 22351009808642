.footer {
  position: relative;
  z-index: 4;
  background-color: $white;
  color: $gray8;

  &.store-flow {

    [href*="giftcard"],
    [href*="Gift-Show"] {
      display: none !important;
    }
  }

  .footer__bar {
    background-color: $carbon_green;
    text-align: center;

    .bar-container {
      display: flex;
      justify-content: center;
      padding: 0 2rem;

      a {
        color: $white;
      }

      .text {
        padding: 0.8rem 1.6rem;
        line-height: 1.6rem;
      }

      .link {
        text-decoration: underline;
      }

      span {
        margin: auto 0;
      }
    }
  }

  .footer-row {
    width: 100%;
  }

  #nlFieldprivacy-label,
  #nlFieldMarketing-label {
    color: $gray8;

    a {
      display: inline;
      text-decoration: underline;
    }
  }

  #newsletter-otherfields label {
    cursor: pointer;
    font-weight: normal;
    left: initial;
    top: initial;
    vertical-align: bottom;
    position: relative;
    padding-left: ($space-unit * 2);
  }

  .container {
    padding: 6.4rem 0 2.4rem;
    margin: 0 2.4rem;
    margin-bottom: 2.4rem;
    width: auto;
    border-bottom: 0.1rem solid $gray3;
  }

  &.footer--clientservice {
    margin-top: 0;
    padding: ($space-unit * 10) 0;
    border-top: 0;

    .container:nth-of-type(1) {
      padding-top: 0;
    }

    .wrapper-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: $space-unit * 8;
      row-gap: 4rem;
    }

    .footer-item {
      &:nth-child(2) {
        grid-column-start: 1;
        grid-row-start: 2;
      }

      h3 {
        @extend %primary-xs-u;
        padding-bottom: ($space-unit * 2);
      }

      p {
        @extend %primary-s;
        margin-bottom: ($space-unit);
      }

      div {
        margin-top: ($space-unit);

        >a {
          @extend %primary-s;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
      }
    }

    .start-chat {
      @extend %primary-s;
      display: flex;
      align-items: center;

      i {
        margin-right: $space-unit;
      }
    }

    .accepted-payments {
      @include reset-list;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;

      li {
        margin-right: ($space-unit * 2);
        margin-bottom: ($space-unit * 2);
      }
    }
  }

  .newsletter-container button[type='submit'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    border: 0;
    right: 1.2rem;
    color: transparent;
    background: transparent;
  }

  a {
    color: $gray8;
  }

  .newsletter-popup-wrapper a {
    color: #666;
  }

  .social-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;
    padding: 0.6rem;
  }

  .footer__nl {
    padding-bottom: ($space-unit * 8);

    &__logo {
      [class^='icon'] {
        display: none;
      }
    }
  }

  .follow-us {
    margin-bottom: 2.5rem;
  }

  .footer-menu {
    @include reset-list;
  }

  .footer-menu__menuitem {
    @extend %primary-xs-u;
    text-decoration: none;
    color: $black;
    display: none;
    @include border('bottom');
    padding: 1rem 0 1rem;
    width: 100%;
    text-align: left;

    &:focus {
      @include focus-underline;
    }
  }

  .footer-countryselector {
    text-decoration: none;
  }

  .footer-menu__menuitem__current {
    @extend %primary-xs;
    text-transform: capitalize;
    color: $white;
    padding-left: ($space-unit * 3);
  }

  .footer-menu__section {
    @include reset-list;
    padding-bottom: ($space-unit * 10);
    text-align: left;

    li {
      &:last-child {
        .footer-menu__section__menuitem {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-menu__section__menuitem {
    display: inline-block;
    text-decoration: none;
    color: $blue;
    margin-bottom: $space-unit * 4;
    @extend %primary-s;
    @include hover(0.2);

    &:focus {
      @include focus-underline;
    }
  }

  #ot-sdk-btn.ot-sdk-show-settings {
    padding: 0;
    cursor: pointer;
    font-family: Satoshi-Medium, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;

    border: none;
    display: inline-block;
    text-decoration: none;
    color: $blue;
    margin-bottom: 1.6rem;

    transition: all 0.2s 0s cubic-bezier(0.25, 1, 0.5, 1);
  }

  #ot-sdk-btn.ot-sdk-show-settings:hover {
    opacity: 0.7;
    color: $blue;
    background-color: transparent;
  }

  .footer-menu--col {
    display: flex;
    flex-direction: column;
    @include border('bottom');

    .footer-menu__menuitem {
      display: flex;
      align-items: center;
      border-bottom: 0;
      @include border('top');
      height: 4.2rem;
      padding: 0;
    }

    .footer-menu__section__menuitem {
      @extend %primary-xs;
      padding: 0;
      margin-bottom: $space-unit * 3;
    }

    .footer-menu__menuitem,
    .footer-menu__section__menuitem {
      &::after {
        content: '';
        @include fixedSprite('plus');
        position: absolute;
        right: -0.8rem;
        top: 50%;
        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        display: none;
      }
    }

    li:nth-child(1) {
      order: 3;
    }

    li:nth-child(2) {
      order: 4;
    }
  }

  .social-legals {
    display: flex;
    flex-direction: column;
    padding-bottom: ($space-unit * 6);
    align-items: flex-end;

    .social-wrapper {
      padding-bottom: ($space-unit * 16);
      display: flex;
      align-items: center;

      >a {
        width: ($space-unit * 4);
        height: ($space-unit * 4);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $space-unit * 4;
        @include hover(0.2);

        &[href*='facebook'] {
          width: ($space-unit * 3);
        }
      }
    }

    .copyright {
      @extend %primary-xxs;
      text-align: right;
    }
  }

  .socials-languages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .languageselector {
    margin-left: auto;
    display: flex;
    justify-content: center;
    border-radius: 0.4rem;
    padding: 0.2rem;

    .language {
      color: $disabled_txt_grey;
      padding: 0.6rem 0.8rem 0.6rem;
      border-radius: 0.2rem;
      text-align: center;

      &.active {
        color: $blue;
        background-color: $white;
        padding: 0.6rem 0.8rem 0.6rem;
        border-radius: 0.2rem;

        a {
          color: $blue;
          text-decoration: underline;
        }
      }

      a {
        color: $disabled_txt_grey;
        text-decoration: none;
        @extend %primary-xs-u;

        &:hover {
          text-decoration: underline;
        }

        .name {
          text-transform: capitalize;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: auto;
    border: 0;
    margin: 0;
    padding: 0 2.4rem 3.2rem;

    a {
      color: $blue;
    }

    .footer-policies {
      margin-left: 0;

      &_wrapper{
        &:not(:first-of-type){
          &::before{
            content: " - ";
            margin-left: 0.6rem;
          }
        }
      }

      &__link{
        font-family: Satoshi-Medium,Arial,sans-serif;
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    .payment-icon {
      margin-right: 0;

      span {
        margin-right: 0.5rem;
      }
    }
  }
}

.language-overlay,
.multicountry-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 150vh;
  background: #222;
  z-index: -1;
  transition: all 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
  opacity: 0;
  visibility: hidden;
}

.language-overlay.visible,
.multicountry-overlay.visible {
  opacity: 0.5;
  visibility: visible;
  z-index: 100;
}

.language-box,
.multicountry-box {
  background-color: $white;
  max-width: 40rem;
  width: 80vw;
  position: fixed;
  z-index: -1;
  border: 0.1rem solid $grey3;
  opacity: 0;
  visibility: hidden;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 100vh;

  &.visible {
    opacity: 1;
    z-index: 110;
    visibility: visible;
  }

  .language-header,
  .multicountry-header {
    padding: 1.8rem 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid $grey3;
  }

  .language-body,
  .multicountry-body {
    display: flex;
    gap: 1.6rem;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 2.4rem;
    padding: 2.4rem 0;

    .form-group {
      width: 100%;

      select {
        padding: 2.4rem 1.6rem 0.6rem;
      }

      label {
        position: absolute;
        top: 0.8rem;
        font-size: 1.2rem;
        left: 1.6rem;
      }
    }
  }

  .language-footer,
  .multicountry-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2.4rem;
    bottom: 0;
    position: absolute;
    width: 100%;
    gap: 0.8rem;
  }
}

.multicountryPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1100;
  pointer-events: none;

  &-box {
    opacity: 1;
    position: absolute;
    right: 2.4rem;
    bottom: 4rem;
    background-color: $white;
    border-radius: 0.8rem;
    width: 31rem;
    transition: all 0.3s ease-out;
    pointer-events: all;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }
  }

  &-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.6rem;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    padding-top: 0;
    gap: 0.8rem;

    #tx-country {
      &-popup {
        padding: 0.8rem 2.8rem 0.8rem 4rem;
        line-height: 2.2rem;
      }

      &-icon-popup {
        position: absolute;
        bottom: 0.8rem;
        left: 0.8rem;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        align-content: center;
        pointer-events: none;
      }
    }

    button {
      width: auto;
      padding: 0 3.8rem;
    }

    .form-group {
      width: 100%;

      select {
        padding: 2.4rem 1.6rem 0.6rem;
      }

      label {
        position: absolute;
        top: 0.8rem;
        font-size: 1.2rem;
        left: 1.6rem;
      }
    }
  }
}

.language-body,
.multicountry-popup-body {

  #tx-country {
    padding: 2.4rem 1.6rem 0.6rem 5.6rem;

    &-icon {
      position: absolute;
      bottom: 0.6rem;
      left: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
    }
  }

  #tx-languages {
    padding: 2.4rem 1.6rem 0.6rem 5.6rem;

    &-icon {
      position: absolute;
      bottom: 0.6rem;
      left: 1.8rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
    }
  }
}

.newsletter-container {
  position: relative;
  display: flex;
  justify-content: stretch;

  .form-group {
    margin-bottom: 0;
    flex-grow: 1;
  }

  .invalid-feedback.globalerrors {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: -1.9rem;
  }
}

.green-text {
  color: green;
}

.footer #newsletter-modal #nlFieldprivacy-label {
  color: $grey8;
}

#newsletter-modal {
  .modal-container {
    position: absolute;
    left: 2.4rem;
    bottom: 5.6rem;

    .modal-close {
      position: absolute;
      top: 0;
      right: 0;
    }

    .modal-content {
      .newsletter-popup-wrapper {
        .title {
          font-size: 2rem;
          line-height: 2.4rem;
        }

        p {
          font-size: 1.4rem;
        }
      }
    }
  }

  &.modal-isml.modal-right .modal-overlay {
    align-items: flex-end;
  }

  &.modal-isml.modal-right .modal-container {
    border-radius: 0.4rem;
    margin: auto;
    max-width: 44rem;
  }

  .modal-isml .modal-close {
    top: 2.4rem;
    right: 2.4rem;
  }

  .newsletter-popup-wrapper {
    padding: 2.4rem;
    color: $gray8;

    .title-5 {
      padding-right: 2rem;
    }
  }

  .modal-content {
    padding: 0;
    height: 100%;
  }

  .footer__nl {
    padding-top: 0;
    padding-bottom: 0;
  }

  h1 {
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 1.33;
  }

  h3 {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.33;
  }

  .newsletter-popup-title {
    font-weight: 500;
    margin-bottom: 1rem;
  }
}

.newsletter-modal {
  &.modal {
    text-align: center;
    min-width: 0;
    height: 45.5rem;
    width: 68.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 95%;
  }

  .modal__header {
    padding: 0;
    margin-bottom: ($space-unit * 4);

    h2 {
      padding-bottom: 0;
    }
  }

  &__description {
    span {
      max-width: 46rem;
      margin: 0 auto ($space-unit * 6);
      display: block;
    }
  }

  &__img-container {
    position: relative;
    width: 50%;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__columns-wrapper {
    width: 100%;
    display: flex;
    height: 100%;
  }

  &--subscription-modal.modal {
    width: 91.6rem;
    height: auto;
    padding: 0;

    .modal {
      &__body {
        width: 100%;
        height: 100%;
      }

      &__header {
        display: none;
      }
    }

    &__description {
      width: 50%;
    }
  }

  &__title {
    margin-bottom: ($space-unit * 2);
    @extend %primary-m;
  }

  &__text-holder {
    text-align: left;
    padding: ($space-unit * 8);
    overflow: auto;
    width: 50%;
    min-height: 60rem;
  }

  &__columns-wrapper {
    .newsletter-modal__description {
      span {
        max-width: 100%;
      }
    }
  }

  &__radio-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;

    >p {
      text-transform: uppercase;
      @extend %primary-xxs;
      margin-bottom: 1.3rem;
    }

    label {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__legal-label {
    margin-top: ($space-unit * 4);
    @extend %primary-xxs;
    color: $gray8;
  }
}

.newsletter-module {
  margin: 7.9rem 0 9.9rem;
  padding: 0 1.6rem;

  .footer__nl {
    padding: 0;
  }

  &__title {
    @extend %primary-l;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.8rem;
  }

  &__description {
    @extend %primary-s;
    width: 44.6rem;
    max-width: 100%;
    margin: 0 auto 3.7rem;
    text-align: center;
  }
}

// for pages with react content, loaded gets added after a delay - set in loader.txt
// experimental - see how to handle it properly :(
#cart-show {
  .footer {
    display: none;
  }

  &.loaded {
    .footer {
      display: block;
    }
  }
}

.privacyflag.checkbox {
  display: flex;

  &.second-flag {
    pointer-events: none;

    input {
      opacity: .5;
    }

    &.active {
      pointer-events: unset;

      input {
        opacity: 1;
      }
    }
  }
}

.newsletter-flag {
  span {
    a {
      text-decoration: underline;
    }
  }
}