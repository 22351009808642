[data-tooltip] {
  position: relative;
  display: inline-block
}

[data-tooltip]:after,
[data-tooltip]:before {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -1.2rem);
  z-index: 1000;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  transition: opacity .3s ease-out;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  background: $blue;
  color: #eee;
  padding: 1.6rem;
  bottom: 100%;
  border-radius: 0.4rem;
  width: max-content;
  max-width: 24rem;
  white-space: normal;

  @media (max-width: 1024px) {
    right: -0.5rem;
    left: unset;
    transform: translate(0, -1.1rem);
  }
}

[data-tooltip]:after {
  content: "";
  background: transparent;
  border: 0.8rem solid transparent;
  border-top-color: $blue
}

[data-tooltip]:active:after,
[data-tooltip]:active:before,
[data-tooltip]:focus:after,
[data-tooltip]:focus:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before {
  opacity: 1
}