.tingle-modal {

  &.right-modal-attribute.mobile-center-bottom,
  &.mobile-center-bottom,
  &.right-modal-attribute.mobile-center-attribute,
  &.mobile-center-attribute {
    .tingle-modal-box {
      position: absolute;
      left: 0;
      right: 0;
      max-width: 100vw;
      width: 100vw;
      max-height: 80vh;
      top: unset;
      bottom: 0;
    }
  }
}

.tingle-modal-box {
  transform: translateY(3rem);
}

.modal-go-back .tingle-modal-box {
  padding: 0;
  border-radius: 0;

  .header-wrapper {
    padding: 2.4rem;
    border-bottom: 0.1rem solid $gray3;
  }

  .body-wrapper {
    padding: 2.4rem;
  }
}

.quickview-modal .tingle-modal-box {
  height: auto;
  bottom: 0;
  top: unset;
  width: 100%;

  .tingle-modal-box__content {
    flex-direction: column;

    .wrapper-body {
      padding: 2.4rem 1.2rem;
      max-height: calc(80vh - 6.4rem - 7.2rem);

      .quickview-image {
        width: 12rem;
        flex: 12rem 0 0;
        margin-right: 1.6rem;

        img:not(:first-child) {
          display: none;
        }
      }

      .detail-panel {
        .size-color-text {
          margin-bottom: 0.8rem;
          color: $grey7;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }

        .quantity,
        .size {
          width: 100%;
          margin: 0;
        }

        .quantity {
          justify-content: space-between;
          padding: 1.2rem 1.6rem;
        }
      }

      &.wrapper-edit {
        max-height: calc(80vh - 6.4rem - 7.2rem);
        overflow: auto;
      }

      .product-quickview {
        height: unset;

        .quickview-content {
          padding: 0;

          .unit-price {
            .price {
              .price {
                .value,
                .js-discount-msg {
                  font-size: 1.3rem;
                }

                &__sale {
                  &-wrapper {
                    padding: 0 0.2rem;
                    gap: 0.8rem;
                  }
                }
              }
            }
          }

          .color {
            display: none;
          }

          .selector__menu {
            padding: 0;

            .size-selector-label-wrapper {
              border: 0;

              .size-default-wrapper {
                display: none;
              }

              .custom-select.select-size {
                margin: 0 1.2rem;
              }
            }
          }

          &__pricePromo {
            flex-direction: column;
            gap: 0.6rem;

            .promo-text {
              
            }
          }

          .quickview-edit-cart {
            &-title {
              margin: 0 0 0.6rem 0;
            }
            &-brand {
              margin-bottom: 0.6rem;
            }
          }
        }
      }
    }
  }
}

.modal-isml .modal-footer,
.tingle-modal .modal-footer {
  padding: 1.6rem;
  position: unset;
}