#checkout-main, #cart-show{
  .cart-checkout-slot{
    .accordion__header{
      padding: 1.4rem 1.2rem;
    }
  }
}

#maincontent .accordion-custom{
  .accordion__header{
    padding: 1.4rem 1.2rem;
  }
  .accordion__panel{
    padding: 0 1.2rem;
  }
}

#search-show{
  .editorial-banner-wrapper{
  }
}