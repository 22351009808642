.product-tile {
  &__info {
    padding-top: ($space-unit * 3);
  }

  &__quick-buy-panel,
  &__quick-buy {
    display: none;
  }
}

.product-tile__card {
  .tag-image {
    max-width: 14rem;

    &-wrapper {
      top: unset;
      flex-direction: row;
    }
  }

  .tile-swiper-icon,
  .wrapper-quick-add-to-bag {
    display: none;
  }
}