.login-registration{
  &__login__social{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
    padding: 1.6rem 0 2.4rem;

    &-wrapper {
      margin-top: 1.6rem;
    }
    
    .icon--link{
      gap: 0.8rem;
    }
  }
}
