.footer {
  &::before {
    content: initial;
  }

  // .container {
  //   &.with-gradient {
  //     @include gray-gradient-pseudo;
  //   }
  // }

  &__nl {
    & > * {
      padding-top: 0;
    }

    &__logo {
      position: relative;
      [class^='icon'] {
        display: block;
        width: 4.4rem;
        height: 3.9rem;
        position: absolute;
        right: ($space-unit * 3);
        top: -0.1rem;
      }
    }

    .form-group__label--floating {
      display: flex;
    }

    &:focus-within {
      .form-group__label--floating {
        display: none;
      }
    }
  }

  .footer-menu {
    display: flex;
    border: none;

    & > li {
      break-inside: avoid;
      flex: 1 1 0;
    }
  }

  .footer-menu__menuitem {
    border-bottom: 0;
    margin-bottom: ($space-unit * 5);
    display: block;
    padding: 0;
  }

  .footer-menu--col {
    .footer-menu__menuitem {
      border-top: 0;
      height: auto;
    }
  }

  .accordion__header {
    display: none;
    &[aria-expanded='false'] + .accordion__panel {
      height: auto !important;
      max-height: unset !important;
      visibility: visible;
      border: 0;
    }
  }

  .footer-menu--col {
    column-count: 1;
    li:nth-child(1),
    li:nth-child(2) {
      order: initial;
    }
  }

  .footer-menu__section {
    display: block;
    padding-bottom: ($space-unit * 8);
  }

  .social-legals {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    .social-wrapper {
      padding-bottom: 0;
    }
  }
}

.newsletter-container {
  padding-bottom: 0;
}
