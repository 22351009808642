@media print {

  header,
  footer {
    display: none;
  }

  .section-menu {
    display: none;
  }

  .accordion__item {
    .accordion__header {

      .header__open,
      .header__close {
        display: none;
      }

      &[aria-expanded='false']+.accordion__panel {
        height: auto !important;
        visibility: visible;
      }
    }
  }

  .myaccount-hero {
    background-color: $white;

    &__title {
      color: $black;
    }
  }
}