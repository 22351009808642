.personalization-modal.quickview-selectsize.modal.sizevariation-modal {
  .modal__body {
    .productDetails {
      &__body {
        &-wrapper {
          width: 29.5vw;
        } 
        
        &__details {
          min-height: calc(var(--viewport-height) - 6.5rem);
          display: flex;
          flex-direction: column;
          .size-container-section {
            .selector-size-expanded {
              width: 66%;
            }
          }
          .personalization {
            &__accordion {
              &-container {
                min-height: calc(var(--viewport-height) - 6.5rem - 3.3rem - 3.2rem - 7.2rem - 4rem - 2.2rem);
              }
            }
          }
        }
      }
    }
  }
}