.form-select {
  select {

    // since the select will always have a default
    &~label {
      top: 0.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &:disabled {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
      background-color: $gray2;

      +label {
        opacity: $input-disabled-opacity;
      }
    }
  }

  .invalid-feedback {
    text-align: right;
  }
}