[data-whatintent='keyboard'] {
  header {

    .wrapper-submenu,
    .menu-actions .icon--menu {
      transition: none;
    }
  }
}

.profileName {
  text-transform: capitalize;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  @include z(header);

  [data-react='minicart-app'] {
    .tmploader {
      display: flex;
    }
  }

  .experience-component.experience-menu_item {
    display: none;
  }

  ul {
    @include reset-list;
  }

  &.checkout {
    position: relative;

    .nav-link {
      &.logout-button {
        display: none;
      }
    }

    +main {
      background: $white;
      padding-top: ($space-unit * 8);
    }

    .navigation {
      h1 {
        @extend %primary-m-u;
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 7.5rem);
    background: $overlay;
    @include z(neg);
    @include animate(0.3);
    opacity: 0;
    visibility: hidden;
  }

  .headerLinksComponent .button {
    justify-content: flex-start;
  }

  .ticker-bar {
    position: relative;
    width: 100%;
    height: $ticker-bar;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    @include animate(0.3, height);
    @extend %primary-xs-u;

    &.clickfromstore {
      background-color: $status_error;
    }

    .icon--close-white {
      position: absolute;
      right: $space-unit;
      top: 50%;
      transform: translateY(-50%);
    }

    &.close {
      height: 0;
    }
  }

  .headerLinksComponent {
    position: relative;
    display: flex;
    flex-direction: column;
    order: 4;
    color: $blue;
    width: 100%;
    padding-top: 3.2rem;
    gap: 2rem;
    margin-top: 3.2rem;
    border-top: 0.1rem solid $gray3;

    .button {
      padding: 0;
      height: auto;
    }
  }

  .loyaltyLink {
    order: 5;
    margin: 3.2rem 0 0;
    padding: 3.2rem 0;
    border-top: 0.1rem solid $gray;
  }

  .navigation {
    // height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include animate(0.3);
  }

  &.checkout .navigation {
    align-items: center;
  }

  .logo-header {
    display: block;
    outline-offset: 0.1rem;
    @include z(logo);
    @include animate(0.3);

    img {
      display: block;
      width: 8rem;
    }
  }

  .wrapper-back {
    display: none;
  }


  .menu-wrapper:has(.underline) .menu-item:not(.open) {

    .menu-heading:not(.underline),
    .menu-heading.underline.notUnderline {
      color: $gray7;
    }
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &:has(.menu-item.open) {
      .menu-item {
        &:not(.open) {
          a {
            color: $gray7;
          }
        }
      }
    }

    .noHover {
      pointer-events: none;
    }
  }

  .menu-footer {
    position: absolute;
    transform: translateX(3.2rem);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
    overflow: hidden;
    background-color: $white;
    width: 100%;
    left: 0;
    top: 47.6rem;
  }

  .menu-heading,
  .submenu-heading {

    &,
    a,
    p {
      display: block;
      @include del-link;
    }
  }

  nav {
    sup {
      vertical-align: super;
      font-size: 0.9rem;
      display: contents;
    }
  }

  .menu-item {
    &:has(.second-level.open) .menu-item.second-level {
      &:not(.open) {
        opacity: 0.6;
      }
    }

    &.category-space {
      &:not(:last-child) {
        margin-bottom: 3.2rem !important;        
      }
    }

    .label-category {
      padding: 0.5rem 0.6rem;
      font-size: 1.3rem;
      vertical-align: text-top;
      border-radius: 0.4rem;
      line-height: 1rem;
    }

    .menu-heading {
      color: $blue;
      appearance: initial;
      line-height: 2.4rem;
      @include animate(0.3);
      @include z;
      font-size: 1.6rem;
      font-weight: 500;

      &.featured {
        color: $red;
      }
    }

    .menu-heading {
      padding: 0 0.4rem 0.4rem 0.4rem;
      margin: 0 0.4rem;
    }
  }

  .submenu-heading {
    margin-bottom: ($space-unit * 5);
    color: $blue;
    min-height: 2rem;
    cursor: default;

    &,
    a,
    p {
      text-transform: uppercase;
    }
  }

  .submenu-group {
    &__list {
      &__element {
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.24rem;

        &:last-child {
          margin-bottom: 0;
        }        
      }

    }
  }

  .submenu-item {
    text-decoration: none;
    color: $grey8;
    // @include hover(0.3);

    &.viewAll {
      color: $blue;
      border-bottom: 0.1rem solid $blue;
      padding-bottom: 0.3rem;
    }

    &:hover {
      color: $dark_blue;
    }

    &.submenu-item-empty {
      color: red;
    }
  }

  .wrapper-submenu {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    @include animate(0.3);
    background-color: $white;
  }

  .third-level {
    position: absolute;
    top: 0;
    height: calc(100vh - var(--nav-wrapper));

    .main-submenu {
      .menu-item {
        a {
          font-size: 1.4rem;
        }
      }
    }
  }

  .visual-submenu,
  .container-submenu {
    // display: flex;
    justify-content: center;
  }

  .container-submenu {
    padding: 4rem;
  }

  .visual-submenu__item {
    flex: 1 0 25%;
    display: block;
    max-width: 25%;
  }

  #mainnav {
    @include animate();
    overflow: hidden;
    display: flex;
    align-items: center;
    width: calc(50% - 4rem);
    justify-content: flex-start;
    padding: 2rem 0 1.6rem 2rem;
  }

  .nav-wrapper.nav-scrolled {
    background-color: $gray1;
    border-bottom: 0.1rem solid $gray3;
    height: 7.2rem;
    transition: background .3s ease-out, border 1.3s ease-out;
  }

  .wrapper-search {

    img,
    [class*='icon--']::after,
    [class*='icon--']::before {
      filter: none !important;
    }
  }

  .wishlist-count,
  #cart-icon .cart-count {
    &:not([data-count='0']) {
      width: auto;
      height: 1.6rem;
      min-width: 1.6rem;
      padding: 0 0.4rem;
      background-color: $blue;
      border-radius: 1rem;
      color: $white;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      bottom: -0.6rem;
      right: -0.5rem;
      left: unset;
      top: unset;
      transform: unset;
    }

    &[data-count='0'] {
      display: none;
    }
  }

  .menu-actions {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    width: calc(50% - 4rem);
    padding-right: 2.4rem;

    [class^='icon']:not(.icon--friends-mini):not(.icon--friends-mini-white) {
      @include hover(0.3);
    }

    .menu-icon {
      .wrapper-search .icon--close {
        padding: 0 2rem;
        width: 6rem;
        height: 1.2rem;
        margin: 1.4rem;
        display: flex;
        background-origin: content-box;
        align-items: center;
      }

      [aria-label="Wishlist"] {
        margin: 0 2.4rem;
      }
    }

    .account-wrapper {
      .account-cta {
        text-decoration: none;
        display: flex;
        align-items: center;

        .account-cta__label {
          @extend %primary-s;
          margin-left: 0.2rem;
        }
      }

      .account-logout {
        @extend %primary-s;
        display: none;
      }
    }

    .icon--bag,
    .icon--wishlist-full,
    .icon--wishlist {
      position: relative;

    }

    .icon--check-filled {
      &::after {
        bottom: 0.4rem;
        right: 0.5rem;
        position: absolute;        
      }
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      height: $space-unit * 4;
      width: $space-unit * 4;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__line {
        width: 1.6rem;
        height: 0.2rem;
        background-color: $black;
        @include animate;

        &:nth-child(1) {
          transform-origin: top right;
        }

        &:nth-child(2) {
          margin: 0.4rem 0;
        }

        &:nth-child(3) {
          transform-origin: bottom right;
        }
      }

      &[aria-expanded='true'] {
        .hamburger__line {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-2.5rem, -1.5rem);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(45deg) translate(-2.5rem, 1.5rem);
          }
        }
      }
    }
  }

  .minibag-wrapper {
    display: flex;

    button.icon--bag.icon--left::before {
      &:not(:has(span)) {
        margin-right: 0;
      }
    }
  }

  .container-nav {
    padding: 0;
    height: 0.02rem;
  }

  .nav-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;

    &.collapse-translate {
      margin-top: calc(0px - var(--nav-wrapper));
      background: $white;
    }

    .first-level-list {
      display: inline;

      .first-level-item {
        display: inline;
      }
    }

    &.expanded {
      background: $white;
    }
  }

  .image-column {
    position: relative;

    >a {
      display: block;
      outline-offset: 0.1rem;
      height: 100%;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      img {
        height: 28rem;
        width: auto;
        object-fit: cover;
      }
    }
  }

  .image-heading {
    position: relative;
    color: $black;
    z-index: 1;
    margin: 0.8rem 0 0 0;
    font-size: 1.4rem;
    line-height: 2rem;


    &:hover {
      text-decoration: underline;
    }
  }

  .icon--menu {
    display: none;
  }

  &.header-page-500 {
    .navigation {
      justify-content: center;
      height: 7.2rem;
      background: $white;
      align-items: center;
    }
  }
}

.widget-1-editorial a {
  text-decoration: none;
}

.widget-1-editorial .img-wrapper {
  max-width: 21rem;

  img {
    max-height: 28rem;
  }
}


#search-show {
  .nav-wrapper.collapse-translate {
    transition-delay: 0s;
  }
}