.personalization-modal {
  &.quickview-selectsize.modal.sizevariation-modal {
    width: auto;
    height: var(--viewport-height);

    .modal__body {
      .productDetails {
        &__img {
          height: 100vh;
          width: auto;

          &-wrapper {
            width: fit-content;
          }
          
        }

        &__body {

          &__header {
            &-title {
              font-size: 1.6rem;
              line-height: 2rem;
            }
            &-wrapper {
              padding: 2.2rem 2.4rem;
              border-bottom: 0.1rem solid $grey3;
            }
            &-btn {
              right: 3.2rem;
              top: 2.2rem;
              z-index: 0;
            }
          }

          &__details {
            padding: 3.3rem 2.6rem 2rem 2.4rem;
            max-height: calc(var(--viewport-height) - 6.5rem);
            overflow: visible;

            .tooltip-quantity {
              position: relative;

              &-modal {
                position: absolute;
                bottom: calc(100% + 0.2rem);
                background: $white;
                padding: 2.4rem;
                right: -1.5rem;
                box-shadow: 0 .4rem .4rem 0 $black_shadow;
                border-radius: .4rem;
                text-align: left; 
                width: 31.8rem;
                
                &__header {
                  padding-bottom: 1rem;
                }

                &:after{
                  content: "";
                  position: absolute;
                  right: 5.1rem;
                  bottom: 0;
                  transform: translate(-50%, 1.1rem);
                  z-index: 1000;
                  pointer-events: none;
                  user-select: none;
                  background: transparent;
                  border: 0.6rem solid transparent;
                  border-top-color: $white;
                }            
              }

            }

            .size-container-section {
              display: flex;
              gap: 1.6rem;

              .counter {
                padding: 1.4rem 1.6rem;
                border: 0.1rem solid $grey3;
                background: $white;
                border-radius: 0.4rem;
                height: 4.8rem;
                display: flex;
                align-items: center;
          
                .btn__container {
                  display: flex;
                  justify-content: space-between;
          
                  .counter__output {
                    font-size: 1.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2rem;
                  }
                }
              }
            }

            .personalization {
              &__position {
                &__btn {
                  padding: 0.8rem 1.2rem;
                  width: fit-content;

                  &-container {
                    padding-bottom: 3.4rem;
                  }
                }
              }
              &__embroidery{
                &__par {
                  font-size: 1rem;
                  padding-bottom: 0.8rem;   
                  color: $grey7;   
                  line-height: 1.6rem;          
                }
                &-text {

                  &__input {
                    &--error {
                      border-color: $status_error;
                      margin-bottom: 0.4rem;
                    }
                  }
                  &-error {
                    margin: 0;
                    padding-top: 0;
                    padding-left: 0.8rem;                  
                  }
                }
                &-style {
                  &__btn {
                    padding: 0.8rem 1.2rem;
                    width: fit-content;

                    &-container {
                      display: flex;
                      flex-wrap: wrap;
                      gap: 1.2rem;
                    }
                  }
                }
                &-color {
                  padding-bottom: 1.4rem;

                  &__menu {
                    &__list {
                      @include reset-list();
                      display: flex;
                      flex-wrap: wrap;
                      gap: 1.2rem;
                    }
                  }
                }
              }

              &__bottom {
                &__message {
                  padding: 0.8rem;
                  border-radius: 0.4rem;
                  background-color: $grey1;  
                  &-wrapper {
                    margin-top: auto;                      
                  }   
                }
              }
            }
          }
        }
      }    
    }
  }
  .personalizedImages__body {
    &-container {
      overflow:hidden;
    }
  }
  .productDetails__img{
    &-wrapper {
      overflow: hidden;
    }
    &--zoom{
      transform-origin: var(--pers-left) var(--pers-top);
      transform: scale(2.3); 
    }
    &-node {
      position: relative;
      
      .imgEmbroideryText{
        position: absolute;
        transform: translate(-50%, -50%);
        font-family: var(--pers-font);
        top: var(--pers-top);
        left: var(--pers-left);
        color: var(--pers-color);
        z-index: 3;
        
        &__icon{
          display: block;
          
          &.blue {
            filter: brightness(0) saturate(100%) invert(14%) sepia(7%) saturate(3034%) hue-rotate(195deg) brightness(100%) contrast(87%);
          }
          &.royal_blue {
            filter: brightness(0) saturate(100%) invert(29%) sepia(10%) saturate(3306%) hue-rotate(198deg) brightness(93%) contrast(98%);
          }
          &.light_blue {
            filter: brightness(0) saturate(100%) invert(31%) sepia(78%) saturate(3349%) hue-rotate(174deg) brightness(99%) contrast(101%);
          }
          &.magenta {
            filter: brightness(0) saturate(100%) invert(29%) sepia(63%) saturate(836%) hue-rotate(284deg) brightness(93%) contrast(77%);
          }
          &.pink {
            filter: brightness(0) saturate(100%) invert(66%) sepia(6%) saturate(3975%) hue-rotate(290deg) brightness(93%) contrast(83%);
          }
          &.red {
            filter: brightness(0) saturate(100%) invert(17%) sepia(95%) saturate(2760%) hue-rotate(340deg) brightness(85%) contrast(87%);
          }
          &.green {
            filter: brightness(0) saturate(100%) invert(30%) sepia(30%) saturate(619%) hue-rotate(48deg) brightness(97%) contrast(85%);
          }
          &.orange {
            filter: brightness(0) saturate(100%) invert(49%) sepia(83%) saturate(3937%) hue-rotate(347deg) brightness(97%) contrast(93%);
          }
          &.yellow {
            filter: brightness(0) saturate(100%) invert(83%) sepia(54%) saturate(3660%) hue-rotate(349deg) brightness(107%) contrast(94%);
          }
          &.peach {
            filter: brightness(0) saturate(100%) invert(70%) sepia(42%) saturate(473%) hue-rotate(330deg) brightness(93%) contrast(82%);
          }
          &.grey {
            filter: brightness(0) saturate(100%) invert(35%) sepia(0%) saturate(3525%) hue-rotate(166deg) brightness(92%) contrast(77%);
          }
          &.black {
            filter: brightness(0) saturate(100%) invert(13%) sepia(16%) saturate(340%) hue-rotate(343deg) brightness(94%) contrast(91%);
          }
        }
      }
    }
  }
}