.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include animate(0.4, all, cubic-bezier(0.6, 0, 0.2, 1));
  cursor: pointer;
  height: 4rem;
  width: 100%;
  border-radius: 0.4rem;
  white-space: nowrap;
  user-select: none;
  padding: 0 3.2rem;
  text-anchor: middle;
  font-family: Satoshi-Medium, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;

  &:disabled {
    opacity: 0.6;
    cursor: none;
    pointer-events: none;
  }

  // PRIMARY & SECONDARY

  &[class*='primary'],
  &[class*='secondary'],
  &[class*='yellow'] {
    text-align: center;
    height: 4rem;

    span {
      font-size: 1.4rem;
      line-height: 1.8rem;

    }
  }

  // PRIMARY

  &--primary,
  &--primary:link,
  &--primary:visited {
    background-color: $blue;
    color: $white;
    border: 0.1rem solid $blue;
    span,
    p,
    button,
    a {
      color: $white;
    }

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }

  // SECONDARY

  &--secondary,
  &--secondary:link,
  &--secondary:visited {
    color: $blue;
    background-color: $white;
    border: 0.1rem solid $gray4;

    span {
      color: $blue;
    }
  }

  //TERTIARY

  &--tertiary,
  &--tertiary:link,
  &--tertiary:visited {
    background-color: $blue;
    color: $white;
    border: 0.1rem solid $white;

    span {
      color: $white;
    }
  }

  // YELLOW

  &--yellow,
  &--yellow:link,
  &--yellow:visited {
    background-color: $yellow;
    color: $blue;

    span {
      color: $blue;
    }
  }

  //ANIMATION

  &--primary,
  &--secondary,
  &--yellow {

    span {
      position: relative;
      display: block;
      overflow: hidden;
      // width: 100%;
      height: auto;
      text-align: center;
    }
  }
}

button {
  font-family: Satoshi-Medium, Arial, sans-serif;
  color: $blue;
}

.icon--paypal .icon--right {
  border-color: $black;
  @include sprite('payment-paypalbutton');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.icon--googlepay .icon--right {
  @include sprite('payment-googlepayexpress');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.icon--amazonpay .icon--right {
  @include sprite('payment-amazonpay');
  background-size: 8.6rem 2.8rem;
  background-position: center 0.4rem;

  // .amazonlogo {
  //   @include fixedSprite('payment-amazonpay');
  //   background-size: 8.6rem 2.8rem;
  //   background-position: center 0.4rem;
  // }
  // .chevron {
  //   @include fixedSprite('payment-amazonpay-chevron');
  //   position: absolute;
  //   right: 1rem;
  //   width: 1.6rem;
  // }
  // .paywith {
  //   position: absolute;
  //   left: 1rem;
  // }
  .adyen-checkout__amazonpay,
  .adyen-checkout__amazonpay__button {
    width: 100% !important;
    height: 3.6rem !important;
    overflow: hidden;
    opacity: 0.2;
  }
}

[data-whatintent='keyboard'] {

  .button.icon--paypal .icon--right,
  .icon--googlepay .icon--right,
  .icon--amazonpay .icon--right,
  .icon--applepay .icon--right {
    &:focus {
      outline: 0.1rem inset $black !important;
      outline-offset: -0.4rem;
    }
  }
}

.use-location {
  position: relative;
  padding: $space-unit;
  margin: 0 $space-unit;

  &::before {
    @include fixedSprite('pin-full');
    content: '';
    display: block;
    margin-top: -0.2rem;
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animation-underline {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(100%);
  }

  51% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes button-rotate {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-105%);
  }

  51% {
    transform: translateY(105%);
  }

  100% {
    transform: translateY(0%);
  }
}

.cta {
  position: relative;
  color: $blue;
  padding-bottom: 0.1rem;
  min-width: fit-content;
  width: max-content;

  &:disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
  }
}

.cta--animated {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  min-width: fit-content;

  &:before {
    content: '';
    display: block;
    border-top: 0.1rem solid $blue;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      border-top: 0.1rem solid $blue;
      animation: animation-underline 1s cubic-bezier(0.4, 0, 0.4, 1);
    }
  }
}

.cta--animated-white {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  color: $white;
  min-width: fit-content;

  &:before {
    content: '';
    display: block;
    border-top: 0.1rem solid $white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      border-top: 0.1rem solid $white;
      animation: animation-underline 1s cubic-bezier(0.4, 0, 0.4, 1);
    }
  }
}

.cta--editorial-animated {
  position: relative;
  width: fit-content;
  overflow: hidden;
  display: flex;
  padding-bottom: 0.1rem;

  &::before {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    background-color: $red;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) scale(1);
    transition: all 0.3s cubic-bezier(0.1, 0.3, 0.4, 1);
  }

  span {
    position: relative;
    padding-bottom: 0.2rem;
    transition: all 0.3s cubic-bezier(0.1, 0.3, 0.4, 1);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $red;
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      transform: translate3d(-100%, 0, 0);
    }
  }

  &:hover {
    &::before {
      transform: translateY(-50%) scale(0);
      transition: all 0.3s cubic-bezier(0.1, 0.3, 0.4, 1);
    }

    span {
      transform: translateX(-1.4rem);
      transition: all 0.3s cubic-bezier(0.1, 0.3, 0.4, 1);

      &::after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.icon--arrow-flow .icon--right {
  width: 1.6rem;
  height: 1.6rem;
  display: grid;
  overflow: hidden;
}

.icon--double-arrow .icon--right:hover:after {
  transform: translate(100%);
}

.arrow--icon {
  width: 1.6rem;
  height: 1.6rem;
  overflow: hidden;
  position: absolute;
  margin-left: 0.9rem;
}

.arrow--button,
.arrow--button+* {
  display: inline-block;
}

.arrow--button:hover+.arrow--icon:after,
.arrow--button:hover+.arrow--icon:before {
  transform: translateX(1.6rem);
}

.arrow--icon:before,
.arrow--icon:after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%23232323'%3e%3cg%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d='M9.53 1.813L15.718 8 9.53 14.187l-1.06-1.06 4.375-4.377H0v-1.5h12.845L8.47 2.873z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  transition: all 0.3s cubic-bezier(0.6, 0, 0.2, 1);
  width: 1.6rem;
  position: absolute;
}

.arrow--icon:after {
  left: 0;
}

.arrow--icon:before {
  left: -1.6rem;
}

.arrow--icon:hover:before,
.arrow--icon:hover:after {
  transform: translateX(1.6rem);
}


.icon--editorial .icon--right {
  width: fit-content;
  padding: 2rem;
  background-color: $grey3;
  display: flex;
  flex-direction: column-reverse;

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    float: right;
  }

  & li {
    text-align: center;
    background-color: $white;
    padding: 0;
  }

  & label,
  & li a {
    @extend .button;
    width: 100%;
    color: $blue;
    text-transform: uppercase;
    height: $button-height-desktop;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: $dark_blue;
    }
  }

  & label {
    margin-top: 1.6rem;
    z-index: 100;
    background-color: $white;
  }

  & label:after {
    content: "";
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.75 2v5.25H14v1.5H8.75V14h-1.5V8.75H2v-1.5h5.25V2z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.8rem;
    transition: transform .3s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input {
    display: none;
  }

  & li {
    box-shadow: inset 0 -0.1rem 0 0 $grey6;
  }

  & input:checked+label:after {
    transform: rotate(180deg) scale(0);
    transition: transform .3s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input:checked+label:before {
    content: '';
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 7.25v1.5H2v-1.5z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 3.2rem;
    transform: rotate(180deg) scale(1);
    transition: transform .3s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input+label:before {
    content: '';

    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 7.25v1.5H2v-1.5z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 3.2rem;
    transition: transform .3s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input:checked~ul li {
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.27, 0, 0.59, 0.99);
  }
}