  //colors
  $black: #222222;
  $black_secondary: rgba(0, 0, 0, 0.5);
  $black_inactive: rgba(0, 0, 0, 0.3);
  $black_shadow: rgba(0, 0, 0, 0.25);

  $white: #ffffff;
  $white_secondary: rgba(255, 255, 255, 0.5);
  $white_inactive: rgba(255, 255, 255, 0.25);
  $white_btn: rgba(255, 255, 255, 0.8);

  $blue: #001526;
  $light_blue: #00adf0;
  $soft_blue: #7aa1d5;
  $dark_blue: #24408e;
  $carbon_green: #80a07e;
  $yellow: #FBBA07;
  $dark-yellow: #CD9D16;
  $special_yellow: #F1FE67;
  $purple: #8136BC;
  $promo_pink: #bb1878;
  $light_orange: #f6af6a;
  $orange: #f6891f;
  $red: #BC4736;
  $redPromo: #BC47361A;
  $green: #3E7D39;
  $greenPromo: #3E7D391A;
  $loyalty: #E03C30;
  $blue_promo: #3A2AFF;
  $lilac: #E3DEFC;
  $blue_vip: #14253c;
  $red_tertiary: rgba(189, 12, 12, 0.03);
  $red_secondary: rgba(189, 12, 12, 0.2);
  $green_secondary: rgba(62, 125, 57, 0.09);


  $grey1: #F9FAFA;
  $grey2: #F1F2F4;
  $grey3: #E3E6E8;
  $grey4: #D8DCDF;
  $grey5: #C4CACF;
  $grey6: #8D959C;
  $grey7: #5C6770;
  $grey8: #404A52;
  $gray1: $grey1;
  $gray2: $grey2;
  $gray3: $grey3;
  $gray4: $grey4;
  $gray5: $grey5;
  $gray6: $grey6;
  $gray7: $grey7;
  $gray8: $grey8;
  $gray: $grey3;
  $grey: $gray;
  $disabled_txt_grey: #adadad;
  $disabled_bg_grey: #e8e8e8;
  $grey_secondary: #232323;

  $status_error: #d92b30;
  $status_error_secondary: rgba(244, 35, 51, 0.15);
  $status_alert: #ffbb00;
  $status_alert_secondary: rgba(255, 187, 0, 0.15);
  $status_success: #50a12a;
  $status_success_secondary: rgba(80, 161, 42, 0.15);
  $status_notify: #2f5fb4;
  $status_notify_secondary: rgba(47, 95, 180, 0.149);

  $grey_input: $grey8;
  $grey_disabled: #f2f2f2;
  $grey_line: #D8D8D8;
  $grey_gradient: #f9f9f9;
  $grey_loader: #e5e5e5;

  $input_text: $grey8;
  $input_background: $grey_disabled;
  $input_border: $grey8;

  $overlay: $black;
  $overlay_base: linear-gradient(360deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);

  $outline: #80bdff;
  $gradient-separator: linear-gradient(180deg, #f9f9f9 0%, #ffffff 100%);
  $input-outline: #333;