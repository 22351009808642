.collapse-translate .feedbackbag__wrapper[data-visible=true] #feedbackbag-container .feedbackbag__overlay {
  .feedbackbag {
    top: 6.4rem;
  }
}

.feedbackbag__wrapper {
  &[data-visible='true'] {
    .feedbackbag{
      position: absolute;
      right: 0;
      top: 0;
      background: $white;
      width: 41rem;
      border-radius: 0.8rem;
      box-shadow: 0 1rem 2rem #00000010;
      transition: all 0.3s ease-out;

      &__container{
        position: relative;      
      }

      &__overlay {
        position: absolute;
        top: 2.2rem;
        right: 0;
        width: 100vw;
        height: calc(100vh);
        transition: all 0.3s ease-out;
        z-index: 1;
      }

      &__item-wrapper{
        max-height: calc(80vh - 6.9rem - 8.8rem - 7.9rem);
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
        .minibag__product{
          &-container{
            border: 0;
          }

          &__actions{
            display: none;
          }

          &__price {
            .sales {
              padding: 0 0.2rem; 
            }
          }
        }
      }

      &__bottom{
        &-btn{
          flex: 1;
          width: 100%;
          overflow: hidden;

          &-wrapper{
            display: flex;
          }          
        }

      }

      img,
      [class*='icon--']::after,
      [class*='icon--']::before {
        filter: none !important;
      }
    }
  }
}
