.product-tile {
  &:hover {
    .swiper-container-initialized {
      .tile-swiper-icon {
        opacity: 1;

        &.swiper-button-disabled {
          opacity: 0.3;
        }
      }      
    }


    .variation-color-plp {
      opacity: 1;
    }

    .cta-quick-add-to-bag {
      opacity: 1;
    }

    .cta-availability-notice {
      opacity: 1;
    }
  }

  &__card {
    .product-image {
      &:hover {
        .tag-image--personalization {
          width: fit-content;  
          .tag-image__label {
            max-width: 20rem;
            padding: 0.4rem 0.4rem 0.4rem 2.1rem;
          }          
        }
      }
    }
  }

  .cta-quick-add-to-bag {
    z-index: 2;
    width: calc(100% - 1.6rem);
    height: 4rem;
    background-color: $white;
    margin: auto 0.8rem 0.8rem 0.8rem;
    border-radius: 0.4rem;
    font-size: 1.4rem;
  }

  .cta-availability-notice {
    z-index: 2;
    width: 4rem;
    height: 4rem;
    background-color: $white;
    margin: auto 0.8rem 0.8rem auto;
    border-radius: 100%;
  }
}